import { getAxiosInstance } from "./Api";
import { AxiosResponse } from "axios";
import { TypeOfSession } from "./-types";
import { GetVotesResponse, VoteType } from "@/types";
import store from "@/store/store";

export default {
  async castVote(
    eventId: number,
    typeOfSession: TypeOfSession,
    type: VoteType,
    userFirebaseUid: string,
    choice: string,
    name: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("cast-vote", {
      eventId: eventId,
      typeOfSession: typeOfSession,
      type: type,
      userFirebaseUid: userFirebaseUid,
      choice: choice,
      name: name,
    });
  },
  async addVotingOption(
    name: string,
    description: string,
    address: string,
    lat: string,
    long: string,
    website: string,
    eventId: string | number
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("add-voting-option", {
      name: name,
      description: description,
      address: address,
      lat: lat,
      long: long,
      website: website,
      eventId: eventId,
      userFirebaseUid: store.state.userFirebaseUid,
    });
  },
  async getVotes(
    eventId: number,
    typeOfSession: TypeOfSession,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-votes", {
      eventId: eventId,
      typeOfSession: typeOfSession,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async getLeadingVote(
    eventId: number,
    typeOfSession: TypeOfSession,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    if (typeOfSession === "choice") {
      return (await getAxiosInstance()).post("get-leading-vote", {
        eventId,
        typeOfSession,
        userFirebaseUid: userFirebaseUid,
      });
    }
  },
};
