
import { defineComponent, PropType } from "vue";
export default defineComponent({
  props: {
    color: String as PropType<"red" | "gray" | "">,
  },
  computed: {
    style() {
      if (this.color === "") {
        return "opacity: 1";
      } else {
        return "background-color: " + this.color + ";";
      }
    },
  },
});
