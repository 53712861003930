import { getAxiosInstance } from "./Api";
import { AxiosResponse } from "axios";
import store from "@/store/store";

export default {
  async getEventAttendees(eventId: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-event-attendees", {
      eventId: eventId,
      userFirebaseUid: store.state.userFirebaseUid,
    });
  },
  async cancelEvent(
    eventId: number,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("cancel-event", {
      eventId: eventId,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async endVoting(
    eventId: number,
    userFirebaseUid: string,
    typeOfSession: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("end-voting", {
      eventId: eventId,
      userFirebaseUid: userFirebaseUid,
      typeOfSession: typeOfSession,
    });
  },
  async getEventUniqueChoices(
    eventId: number,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-event-unique-choices", {
      eventId,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async loadRestaurants(
    eventId: number,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("load-restaurants", {
      eventId: eventId,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async loadRestaurantsAndVotes(
    eventId: number | string,
    userFirebaseUid: string,
    extended: boolean,
    requestMore: boolean
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("load-restaurants-and-votes", {
      eventId: eventId,
      userFirebaseUid: userFirebaseUid,
      extended: extended ?? false,
      requestMore: requestMore ?? false,
    });
  },
  async getEventWinner(
    eventId: number,
    typeOfSession: string,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-event-winner", {
      eventId,
      typeOfSession,
      userFirebaseUid: userFirebaseUid,
    });
  },
  // subscribeDeviceToReceiveNotifications(userFirebaseUid: string): Promise<AxiosResponse> {
  //   return (await getAxiosInstance()).post("subscribe-device-to-receive-notifications", {userFirebaseUid: userFirebaseUid});
  // },
  async sendUserNotification(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("send-user-notification", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async getEventDetails(eventId: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-event-details", {
      userFirebaseUid: store.state.userFirebaseUid,
      eventId: eventId,
    });
  },
  async getUpcomingEventsAndEndingVotes(
    userFirebaseUid: string,
    showCanceled: boolean,
    showNotAttending: boolean
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post(
      "get-upcoming-events-and-ending-votes",
      {
        userFirebaseUid: userFirebaseUid,
        showCanceled: showCanceled,
        showNotAttending: showNotAttending,
      }
    );
  },
  async deleteFromVote(
    eventId: number,
    id: string,
    type: string,
    name: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("delete-from-vote", {
      eventId: eventId,
      id: id,
      type: type,
      name: name,
      userFirebaseUid: store.state.userFirebaseUid,
    });
  },
};
