import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-center p-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "fw-semibold list-group list-group-flush border border-dark border-1 rounded-2 list-group-numbered my-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.attendees.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "No one has RSVP'd yet!"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attendees, (a) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(_ctx.getListItemClass(a.attendingStatus)),
            key: a.userFirebaseUid
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.getIcon(a.attendingStatus))
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(a.userAlias), 1)
          ], 2))
        }), 128))
      ])
    ])
  ]))
}