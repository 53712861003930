import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn border border-0 d-flex flex-column justify-content-center align-items-center",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.moveToNewRoute && _ctx.moveToNewRoute(...args)))
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.isActive ? _ctx.activeIconClass : _ctx.inactiveIconClass)
    }, null, 2),
    _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
  ]))
}