
import { defineComponent } from "vue";
import { Offcanvas } from "bootstrap";
import {
  showFeedbackModal,
  showGuestWarningModal,
} from "./Modals/ModalController";
import { userStatusMixin } from "@/mixins/isUserLoggedInMixin";
import { showNavButtons } from "@/mixins/showNavButtonsMixin";
export default defineComponent({
  mixins: [userStatusMixin, showNavButtons],
  mounted() {
    this.loading = true;
    const navLinks = document.querySelectorAll(".nav-item");
    const offCanvas = document.getElementById("header-links");
    const bsOffCanvas = Offcanvas.getOrCreateInstance(offCanvas);
    bsOffCanvas.hide();
    navLinks.forEach((l) => {
      l.addEventListener("click", () => {
        if (window.innerWidth < 991 && !this.loading) {
          bsOffCanvas.hide();
        }
      });
    });
    this.loading = false;
  },
  data() {
    return { loading: true as boolean };
  },
  computed: {
    dev() {
      return process?.env?.NODE_ENV === "development";
    },
    offcanvasClass() {
      return window.innerWidth < 500
        ? "offcanvas offcanvas-end w-75"
        : "offcanvas offcanvas-end w-50";
    },
  },
  methods: {
    showFeedbackModal,
    async showFeedbackAndCloseOffCanvas() {
      this.closeOffCanvas();
      await new Promise((resolve) => setTimeout(resolve, 800));
      this.showFeedbackModal();
    },
    showGuestWarningModal,
    closeOffCanvas() {
      const offCanvas = document.getElementById("header-links");
      const bsOffCanvas = Offcanvas.getInstance(offCanvas);
      bsOffCanvas.hide();
    },
    async logout() {
      this.closeOffCanvas();
      //   TODO fix this hack: delay for 1 second to allow offcanvas to close
      await new Promise((resolve) => setTimeout(resolve, 800));

      if (this.$store.state.signedInAsGuest) {
        this.showGuestWarningModal();
      } else {
        this.$store.dispatch("logout");
      }
    },
  },
  components: {},
});
