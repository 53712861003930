import { createStore } from "vuex";
import { State } from "./-types";
import VuexPersistence from "vuex-persist";
import {
  Event,
  SessionSelected,
  Team,
  UserLocation,
  TeamUuidRedirect,
} from "../types";
import { updateAuthorizationHeader } from "@/services/Api";
import {
  EventWizardContext,
  GuestSessionDetails,
} from "@/components/Wizard/-types";
import { generateGuestSessionDetails } from "@/components/GuestWheelSession/-guestWheelSessionFactory";
import { generateEventWizardContext } from "../components/Event/eventFactory";
const vuexLocal = new VuexPersistence<State>({
  storage: window.localStorage,
});
import { getAuthInstance } from "../firebase/config";
import { getAuth, signOut } from "firebase/auth";
import router from "@/router/router";
import { deleteAllData } from "@/services/AuthenticationService";
import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

export const defaultStoreState = {
  token: null,
  userAlias: null,
  userFirebaseUid: null,
  teamContext: null,
  locationContext: null,
  guestWizardContext: null,
  eventWizardContext: null,
  isRegisteredForNotifications: false,
  userEvents: [],
  userTeams: [],
  userLocations: [],
  teamUuidsToAddFromRedirect: [],
  storeVersion: 1,
  loading: false,
  seenVotingRules: false,
  refreshingAuthState: false,
  shouldRefreshComponentData: false,
  showNotAttending: false,
  showCanceled: true,
  signedInAsGuest: false,
  isRedirectingForLogin: false,
  hasSeenUpdateUserAliasWarning: false,
};

export const isUserLoggedIn = () => {
  return store.state.userFirebaseUid !== null;
};

const store = createStore({
  state(): State {
    return {
      token: null,
      userFirebaseUid: null,
      userAlias: null,
      teamContext: null,
      locationContext: null,
      guestWizardContext: null,
      eventWizardContext: null,
      isRegisteredForNotifications: false,
      userEvents: [],
      userTeams: [],
      userLocations: [],
      teamUuidsToAddFromRedirect: [],
      storeVersion: 1,
      loading: false,
      seenVotingRules: false,
      refreshingAuthState: false,
      shouldRefreshComponentData: false,
      showNotAttending: false,
      showCanceled: true,
      signedInAsGuest: false,
      isRedirectingForLogin: false,
      hasSeenUpdateUserAliasWarning: false,
    };
  },
  mutations: {
    setLoading(state: State, loading: boolean) {
      state.loading = loading;
    },
    setShouldRefreshComponentData(state: State, shouldRefresh: boolean) {
      state.shouldRefreshComponentData = shouldRefresh;
    },
    setRefreshingAuthState(state: State, refreshing: boolean) {
      state.refreshingAuthState = refreshing;
    },
    clearTeamUuidRedirects(state: State) {
      state.teamUuidsToAddFromRedirect = [];
    },
    setUserFirebaseUid(state: State, uid: string | null) {
      state.userFirebaseUid = uid;
    },
    pushTeamUuidRedirect(state: State, teamUuid: TeamUuidRedirect) {
      state.teamUuidsToAddFromRedirect.push(teamUuid);
    },
    setShowNotAttending(state: State, show: boolean) {
      state.showNotAttending = show;
    },
    setShowCanceled(state: State, show: boolean) {
      state.showCanceled = show;
    },
    setIsRedirectingForLogin(state: State, isRedirecting: boolean) {
      state.isRedirectingForLogin = isRedirecting;
    },
    setSignedInAsGuest(state: State, value: boolean) {
      state.signedInAsGuest = value;
    },
    setHasSeenUpdateUserAliasWarning(state: State, value: boolean) {
      state.hasSeenUpdateUserAliasWarning = value;
    },
    setUserAlias(state: State, userAlias: string | null) {
      if (userAlias !== null) {
        state.userAlias = userAlias.toLowerCase();
      } else {
        state.userAlias = null;
      }
    },
    setLocationContext(state: State, location: UserLocation | null) {
      state.locationContext = location;
    },
    setSeenVotingRules(state: State, seen: boolean) {
      state.seenVotingRules = seen;
    },
    setTeamContext(state: State, team: Team | null) {
      state.teamContext = team;
    },
    setIsRegisteredForNotifications(state: State, isRegistered: boolean) {
      state.isRegisteredForNotifications = isRegistered;
    },
    setGuestWizardContext(state: State, details: GuestSessionDetails | null) {
      if (details === undefined) {
        state.guestWizardContext = generateGuestSessionDetails();
      } else {
        state.guestWizardContext = details;
      }
    },
    setGuestWizardChoicesContext(state: State, choices: string[] | null) {
      state.guestWizardContext.selectedChoices = choices;
    },
    setGuestWizardCoordinatesContext(state: State, coordinates) {
      state.guestWizardContext.coordinates.lat = coordinates.lat;
      state.guestWizardContext.coordinates.lat = coordinates.long;
    },
    setGuestWizardRadiusContext(state: State, radius: string) {
      state.guestWizardContext.radius = radius;
    },
    setEventWizardContext(state: State, details: EventWizardContext | null) {
      if (details === undefined || details === null) {
        state.eventWizardContext = generateEventWizardContext();
      } else {
        state.eventWizardContext = details;
      }
    },
    setEventWizardTeamNameContext(state: State, teamName: string) {
      state.eventWizardContext.teamName = teamName;
    },
    setEventWizardTeamIdContext(state: State, teamId: string) {
      state.eventWizardContext.teamId = teamId;
    },
    setEventWizardNameContext(state: State, name: string) {
      state.eventWizardContext.eventName = name;
    },
    setEventWizardLocationNicknameContext(state: State, name: string) {
      state.eventWizardContext.locationNickname = name;
    },
    setEventWizardIncludeDrinksContext(state: State, includeDrinks: boolean) {
      state.eventWizardContext.includeDrinks = includeDrinks;
    },
    setEventWizardIncludeFoodContext(state: State, includeFood: boolean) {
      state.eventWizardContext.includeFood = includeFood;
    },
    setEventWizardIncludeEventsContext(state: State, includeEvents: boolean) {
      state.eventWizardContext.includeEvents = includeEvents;
    },
    setEventWizardCoordinatesContext(state: State, coordinates) {
      state.eventWizardContext.coordinates.lat = coordinates.lat;
      state.eventWizardContext.coordinates.long = coordinates.long;
    },
    setEventWizardRadiusContext(state: State, radius: string) {
      state.eventWizardContext.radius = radius;
    },
    setEventWizardEventStartDateTimeContext(
      state: State,
      eventStartDateTime: Date
    ) {
      state.eventWizardContext.eventStartDateTime = eventStartDateTime;
    },
    setEventWizardRestaurantVoteEndDateTimeContext(state: State, date: Date) {
      state.eventWizardContext.restaurantVoteEndDateTime = date;
    },
    setEventWizardSessionTypeContext(state: State, st: SessionSelected) {
      state.eventWizardContext.sessionType = st;
    },
    setUserTeams(state: State, teams: Team[]) {
      state.userTeams = teams;
    },
    setUserLocations(state: State, locations: UserLocation[]) {
      state.userLocations = locations;
    },
    setUserEvents(state: State, events: Event[]) {
      state.userEvents = events;
    },
  },
  actions: {
    setLoading({ commit }, loading: boolean) {
      commit("setLoading", loading);
    },
    setShowNotAttending({ commit }, show: boolean) {
      commit("setShowNotAttending", show);
    },
    setShowCanceled({ commit }, show: boolean) {
      commit("setShowCanceled", show);
    },
    setSignedInAsGuest({ commit }, value: boolean) {
      commit("setSignedInAsGuest", value);
    },
    setHasSeenUpdateUserAliasWarning({ commit }, value: boolean) {
      commit("setHasSeenUpdateUserAliasWarning", value);
    },
    setShouldRefreshComponentData({ commit }, shouldRefresh: boolean) {
      commit("setShouldRefreshComponentData", shouldRefresh);
    },
    setIsRedirectingForLogin({ commit }, isRedirecting: boolean) {
      commit("setIsRedirectingForLogin", isRedirecting);
    },
    setRefreshingAuthState({ commit }, refreshing: boolean) {
      commit("setRefreshingAuthState", refreshing);
    },
    async logout({ commit }, goToLoginNotHome = false) {
      // guest on web
      if (store.state.signedInAsGuest && !Capacitor.isNativePlatform()) {
        const auth = getAuth();
        //  double check that the user is signed in as a guest, then clean up backend data
        const user = auth.currentUser;
        if (user?.isAnonymous) {
          try {
            await deleteAllData(store.state.userFirebaseUid);
            await FirebaseAuthentication.deleteUser();
          } catch (e) {
            console.error("Error deleting user data", e);
          }
        }
      }
      //   non-guest on web - onauthstatechange will handle the store cleanup
      if (!Capacitor.isNativePlatform()) {
        try {
          await signOut(getAuth());
        } catch (e) {
          console.error("Error signing out", e);
        }
      }
      //   no guests allowed on native - sign out
      else {
        try {
          await FirebaseAuthentication.signOut();
          store.commit("setUserAlias", null);
          store.commit("setUserFirebaseUid", null);
          store.commit("setSignedInAsGuest", false);
          updateAuthorizationHeader(null);
        } catch (e) {
          console.error("Error signing out native", e);
        }
      }
      if (goToLoginNotHome) {
        router.push({ name: "Login" });
      } else {
        router.push({ name: "Home" });
      }
    },
    setUserFirebaseUid({ commit }, uid: string | null) {
      commit("setUserFirebaseUid", uid);
    },
    clearTeamUuidRedirects({ commit }) {
      commit("clearTeamUuidRedirects");
    },
    pushTeamUuidRedirect({ commit }, t: TeamUuidRedirect) {
      commit("pushTeamUuidRedirect", t);
    },
    setUserAlias({ commit }, userAlias: string | null) {
      commit("setUserAlias", userAlias);
    },
    setTeamContext({ commit }, team: Team | null) {
      commit("setTeamContext", team);
    },
    setLocationContext({ commit }, location: UserLocation | null) {
      commit("setLocationContext", location);
    },
    setSeenVotingRules({ commit }, seen: boolean) {
      commit("setSeenVotingRules", seen);
    },
    setGuestWizardContext({ commit }, details: GuestSessionDetails | null) {
      commit("setGuestWizardContext", details);
    },
    setEventWizardContext({ commit }, details: EventWizardContext | null) {
      commit("setEventWizardContext", details);
    },
    setGuestWizardChoicesContext({ commit }, choices: string[] | null) {
      commit("setGuestWizardChoicesContext", choices);
    },
    setGuestWizardCoordinatesContext({ commit }, coordinates) {
      commit("setGuestWizardCoordinatesContext", coordinates);
    },
    setGuestWizardRadiusContext({ commit }, radius: string) {
      commit("setGuestWizardRadiusContext", radius);
    },
    setIsRegisteredForNotifications({ commit }, isRegistered: boolean) {
      commit("setIsRegisteredForNotifications", isRegistered);
    },
    setEventWizardTeamNameContext({ commit }, teamName: string) {
      commit("setEventWizardTeamNameContext", teamName);
    },
    setEventWizardTeamIdContext({ commit }, teamId: string) {
      commit("setEventWizardTeamIdContext", teamId);
    },
    setEventWizardNameContext({ commit }, name: string) {
      commit("setEventWizardNameContext", name);
    },
    setEventWizardLocationNicknameContext({ commit }, name: string | null) {
      commit("setEventWizardLocationNicknameContext", name);
    },
    setEventWizardIncludeDrinksContext({ commit }, includeDrinks: boolean) {
      commit("setEventWizardIncludeDrinksContext", includeDrinks);
    },
    setEventWizardIncludeFoodContext({ commit }, includeFood: boolean) {
      commit("setEventWizardIncludeFoodContext", includeFood);
    },
    setEventWizardIncludeEventsContext({ commit }, includeEvents: boolean) {
      commit("setEventWizardIncludeEventsContext", includeEvents);
    },
    setEventWizardCoordinatesContext({ commit }, coordinates) {
      commit("setEventWizardCoordinatesContext", coordinates);
    },
    setEventWizardSessionTypeContext({ commit }, st: SessionSelected) {
      commit("setEventWizardSessionTypeContext", st);
    },
    setEventWizardRadiusContext({ commit }, radius) {
      commit("setEventWizardRadiusContext", radius);
    },
    setEventWizardEventStartDateTimeContext(eventStartDateTime) {
      this.commit(
        "setEventWizardEventStartDateTimeContext",
        eventStartDateTime
      );
    },
    setEventWizardRestaurantVoteEndDateTimeContext(restaurantVoteEndDateTime) {
      this.commit(
        "setEventWizardRestaurantVoteEndDateTimeContext",
        restaurantVoteEndDateTime
      );
    },
    setUserTeams({ commit }, teams: Team[]) {
      commit("setUserTeams", teams);
    },
    setUserLocations({ commit }, locations: UserLocation[]) {
      commit("setUserLocations", locations);
    },
    setUserEvents({ commit }, events: Event[]) {
      commit("setUserEvents", events);
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
