import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvancedImage = _resolveComponent("AdvancedImage")!

  return (_ctx.url)
    ? (_openBlock(), _createBlock(_component_AdvancedImage, {
        key: 0,
        cldImg: _ctx.img,
        class: "rounded-1 my-2"
      }, null, 8, ["cldImg"]))
    : _createCommentVNode("", true)
}