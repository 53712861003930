
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    numberOfVotes: Number,
    aliasesWhoVotedForThis: Array<string>,
    loading: Boolean,
  },
  computed: {
    someVotes() {
      return this.numberOfVotes > 0;
    },
    heartClass() {
      return (this.aliasesWhoVotedForThis ?? []).includes(
        this.$store.state.userAlias
      )
        ? "bi bi-heart-fill fs-3 p-1"
        : "bi bi-heart fs-3 p-1";
    },
  },
  methods: {
    vote() {
      this.$emit("vote");
    },
  },
});
