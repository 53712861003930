import { getAxiosInstance } from "./Api";
import { Choice } from "../-types";
import { AxiosResponse } from "axios";
import store from "@/store/store";

export default {
  // object which allows us to call register method that allows us to hit register endpoint
  async updateCuisineSelection(
    userFirebaseUid: string,
    choices: Choice[]
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("update-cuisine-selection", {
      userFirebaseUid: userFirebaseUid,
      choices,
    });
  },
  async getCuisineSelection(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-cuisine-selection", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  //   testGetShortTimeToken(userFirebaseUid: string): Promise<AxiosResponse> {
  //     return (await getAxiosInstance()).post("test-get-short-time-token", {
  //       userFirebaseUid: userFirebaseUid,
  //     });
  //   },
  async getUserLocations(uid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-user-locations", {
      userFirebaseUid: uid,
    });
  },
  async updateUserAlias(
    userFirebaseUid: string,
    userAlias: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("update-user-alias", {
      userFirebaseUid: userFirebaseUid,
      userAlias: userAlias.toLowerCase(),
    });
  },
  async getUserAlias(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-user-alias", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async addUserLocation(
    userFirebaseUid: string,
    nickname: string,
    address: string,
    placeLat: string,
    placeLong: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("add-user-location", {
      userFirebaseUid: userFirebaseUid,
      nickname,
      address,
      placeLat,
      placeLong,
    });
  },
  async deleteUserLocation(
    userFirebaseUid: string,
    address: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("delete-user-location", {
      userFirebaseUid: userFirebaseUid,
      address,
    });
  },
  async getUserEvents(
    userFirebaseUid: string,
    wantsOldEvents = false as boolean,
    showCanceled = true as boolean,
    showNotAttending = true as boolean
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-user-events", {
      userFirebaseUid: userFirebaseUid,
      wantsOldEvents: wantsOldEvents,
      showCanceled: showCanceled,
      showNotAttending: showNotAttending,
    });
  },
  async changeRSVP(status: number, eventId: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("change-rsvp", {
      status,
      eventId,
      userFirebaseUid: store.state.userFirebaseUid,
    });
  },
  async userHasTeams(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("user-has-teams", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async userHasEvents(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("user-has-events", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async userHasPreferences(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("user-has-preferences", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async userHasLocations(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("user-has-locations", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async addNotificationRegistrationToken(
    userFirebaseUid: string,
    token: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post(
      "add-notification-registration-token",
      {
        userFirebaseUid: userFirebaseUid,
        token: token,
      }
    );
  },
  // refreshes all of the user data like locations, teams, etc to populate the store
  async refreshUserData(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("refresh-user-data", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async isUserRegisteredForNotifications(
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post(
      "is-user-registered-for-notifications",
      {
        userFirebaseUid: userFirebaseUid,
      }
    );
  },
  async mergeUserFirebaseUid(
    oldUid: string,
    newUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("merge-userFirebaseUid", {
      oldUid: oldUid,
      newUid: newUid,
    });
  },
};
