import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5ec95890"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__title d-flex flex-row justify-content-between align-items-center text-secondary" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, _mergeProps(_ctx.$attrs, {
    classes: [
      'modal-container',
      'col-sm-12',
      'col-md-8',
      'col-xl-6',
      'mx-auto',
    ],
    "content-class": "bg-light p-2 d-flex flex-column rounded-4 overflow-y-auto m-3",
    name: _ctx.componentName,
    "content-transition": "vfm-slide-up",
    "overlay-transition": "vfm-slide-up",
    "click-to-close": _ctx.clickToClose,
    "display-directive": "show"
  }), {
    default: _withCtx(({ params, close }) => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true),
        (_ctx.showCloseButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-close btn-lg",
              onClick: ($event: any) => (_ctx.$emit('cancel', close)),
              "aria-label": "Close"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {
          params: params,
          onClose: close
        }, undefined, true)
      ])
    ]),
    _: 3
  }, 16, ["name", "click-to-close"]))
}