//update things referred to on backend
// when cuisineChoices.js is changed

import { Choice } from "../-types";

export const choices: Choice[] = [
  { selected: false, name: "American", keyword: "american" },
  { selected: false, name: "Afghan", keyword: "afghan" },
  { selected: false, name: "African", keyword: "african" },
  { selected: false, name: "Bar", keyword: "bar" },
  { selected: false, name: "BBQ", keyword: "bbq" },
  { selected: false, name: "Brewery", keyword: "brewery" },
  { selected: false, name: "Brunch", keyword: "brunch" },
  { selected: false, name: "Cafe", keyword: "cafe" },
  { selected: false, name: "Cajun", keyword: "cajun" },
  { selected: false, name: "Caribbean", keyword: "caribbean" },
  { selected: false, name: "Chinese", keyword: "chinese" },
  { selected: false, name: "Dessert", keyword: "dessert" },
  { selected: false, name: "Fast Food", keyword: "fastFood" },
  { selected: false, name: "French", keyword: "french" },
  { selected: false, name: "Greek", keyword: "greek" },
  { selected: false, name: "Indian", keyword: "indian" },
  { selected: false, name: "Italian", keyword: "italian" },
  { selected: false, name: "Japanese", keyword: "japanese" },
  { selected: false, name: "Lebanese", keyword: "lebanese" },
  { selected: false, name: "Literally Anything", keyword: "restaurant" },
  { selected: false, name: "Local", keyword: "local" },
  { selected: false, name: "Mexican", keyword: "mexican" },
  { selected: false, name: "Moroccan", keyword: "moroccan" },
  { selected: false, name: "Korean", keyword: "korean" },
  { selected: false, name: "Mediterranean", keyword: "mediterranean" },
  { selected: false, name: "Pizza", keyword: "pizza" },
  { selected: false, name: "Spanish", keyword: "spanish" },
  { selected: false, name: "Thai", keyword: "thai" },
  { selected: false, name: "Turkish", keyword: "turkish" },
  { selected: false, name: "Vegan", keyword: "vegan" },
  { selected: false, name: "Vegetarian", keyword: "vegetarian" },
  { selected: false, name: "Vietnamese", keyword: "vietnamese" },
  { selected: false, name: "Winery", keyword: "winery" },
];

export const ChoiceKeywords = [
  "afghan",
  "african",
  "american",
  "bar",
  "bbq",
  "brewery",
  "brunch",
  "cafe",
  "cajun",
  "caribbean",
  "chinese",
  "dessert",
  "fastFood",
  "french",
  "greek",
  "indian",
  "italian",
  "japanese",
  "korean",
  "lebanese",
  "local",
  "mediterranean",
  "mexican",
  "moroccan",
  "pizza",
  "restaurant",
  "spanish",
  "thai",
  "turkish",
  "vegan",
  "vegetarian",
  "vietnamese",
  "winery",
];

export const simplifiedChoices = [
  {
    selected: false,
    name: "Asian",
    keywords: ["chinese", "japanese", "thai", "vietnamese", "korean"],
  },
  { selected: false, name: "Hispanic", keywords: ["mexican", "spanish"] },
  {
    selected: false,
    name: "Middle Eastern",
    keywords: ["afghan", "lebanese", "moroccan"],
  },
  {
    selected: false,
    name: "American",
    keywords: [
      "american",
      "fastFood",
      "brunch",
      "bbq",
      "cajun",
      "pizza",
      "restaurant",
      "italian",
    ],
  },
  {
    selected: false,
    name: "Drinks and Bars",
    keywords: ["bar", "brewery", "winery"],
  },
  {
    selected: false,
    name: "Vegetarian or Vegan",
    keywords: ["vegetarian", "vegan"],
  },
  {
    selected: false,
    name: "Mediterranean",
    keywords: ["turkish", "greek", "mediterranean"],
  },
  {
    selected: false,
    name: "Something Different",
    keywords: [
      "african",
      "cafe",
      "caribbean",
      "dessert",
      "french",
      "indian",
      "italian",
      "local",
    ],
  },
];
