import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "card d-flex flex-column overflow-auto w-100",
  ref: "chatScrollContainer",
  style: {"max-height":"20em"}
}
const _hoisted_2 = {
  key: 0,
  class: "card text-center p-4"
}
const _hoisted_3 = {
  key: 1,
  class: "list-group"
}
const _hoisted_4 = { class: "d-flex flex-row mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatMessageVue = _resolveComponent("ChatMessageVue")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.reactiveMessages.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " No messages yet! "))
        : (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactiveMessages, (message) => {
              return (_openBlock(), _createBlock(_component_ChatMessageVue, {
                key: message.id,
                message: message
              }, null, 8, ["message"]))
            }), 128))
          ]))
    ], 512),
    _createElementVNode("span", null, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("textarea", {
          onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)), ["enter"])),
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newMessage) = $event)),
          name: "chatbox",
          "aria-describedby": "helpId",
          autocomplete: "off",
          placeholder: "Send a message..."
        }, null, 544), [
          [_vModelText, _ctx.newMessage]
        ]),
        _createElementVNode("button", {
          class: "btn btn-nice-blue",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)))
        }, "Send")
      ])
    ])
  ]))
}