
import TeamsService from "@/services/TeamsService";
import { defineComponent } from "vue";
import { getTeams } from "../../common/common";
import SpSepInputContainer from "../FormComponents/SpSepInputContainer.vue";
import { TYPE, useToast } from "vue-toastification";

export default defineComponent({
  data() {
    return {
      showTeamMemberEntry: false as boolean,
      shouldDisableBecauseTyping: false as boolean,
      invitees: [] as string[],
      teamName: "" as string,
      creatingTeam: false as boolean,
      showEnterButtonMessage: false as boolean,
    };
  },
  computed: {
    createTeamDisabled(): boolean {
      return (
        !this.teamName || this.creatingTeam || this.shouldDisableBecauseTyping
      );
    },
  },
  methods: {
    typing() {
      this.shouldDisableBecauseTyping = true;
      this.showEnterButtonMessage = true;
    },
    ended() {
      this.shouldDisableBecauseTyping = false;
      this.showEnterButtonMessage = false;
    },
    updateInvitees(i: string[]) {
      this.invitees = JSON.parse(JSON.stringify(i));
    },
    async createTeam() {
      try {
        if (!this.creatingTeam) {
          this.creatingTeam = true;
          //TODO check for bad usernames addresses and error
          let cleanArrayOfInvitees = [];
          for (let i of this.invitees) {
            i = i.trim().toLowerCase();
            cleanArrayOfInvitees.push(i);
          }
          const response = await TeamsService.createTeam(
            this.$store.state.userFirebaseUid,
            this.teamName,
            cleanArrayOfInvitees
          );
          const extraMsg =
            response.data.usersNotFound.length > 0
              ? " Couldn't add the following users: " +
                response.data.usersNotFound.join(", ") +
                "."
              : "";

          const toast = useToast();
          toast("Team created." + extraMsg, {
            timeout: 2500,
            type: TYPE.DEFAULT,
            hideProgressBar: true,
          });

          await this.$store.dispatch(
            "setEventWizardTeamIdContext",
            response.data.team.uuidPrimaryKey
          );
          await this.$store.dispatch(
            "setEventWizardTeamNameContext",
            response.data.team.teamName
          );
          const t = await getTeams();
          await this.$store.dispatch("setUserTeams", t);
          this.$emit("close");
        }
      } catch (error: unknown) {
        console.log(error);
      } finally {
        this.creatingTeam = false;
      }
    },
  },
  components: { SpSepInputContainer },
});
