
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from "@cloudinary/vue";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { defineComponent, PropType } from "vue";
import { AlternateClassification } from "./-.types";

// Create a Cloudinary instance and set your cloud name.
const cld = new Cloudinary({
  cloud: {
    cloudName: "dem6mbuwi",
  },
});

export default defineComponent({
  props: {
    url: { type: String },
    basePath: { type: String, default: "" },
    altClassification: String as PropType<AlternateClassification | undefined>,
  },
  data() {
    let img;
    if (this.url !== "") {
      img = cld.image(this.basePath + this.url);
      img.resize(fill()); // Default size
    }
    return { img };
  },
  //   computed: {
  //     srcSet() {
  //       return `
  //       ${this.getTransformedUrl(300)} 300w,
  //       ${this.getTransformedUrl(600)} 600w,
  //       ${this.getTransformedUrl(900)} 900w,
  //       ${this.getTransformedUrl(1200)} 1200w,
  //       ${this.getTransformedUrl(1500)} 1500w,
  //       ${this.getTransformedUrl(1800)} 1800w
  //     `;
  //     },
  //     sizes() {
  //       return `
  //       (max-width: 300px) 300px,
  //       (max-width: 600px) 600px,
  //       (max-width: 900px) 900px,
  //       (max-width: 1200px) 1200px,
  //       (max-width: 1500px) 1500px,
  //       1800px
  //     `;
  //     },
  //   },
  //   methods: {
  // getTransformedUrl(width) {
  //   const img = cld.image(this.url);
  //   img.resize(
  //     fill()
  //       .width(width)
  //       .height(Math.round(width * 0.67))
  //   ); // Adjust height to maintain aspect ratio
  //   return img.toURL();
  // },
  //   },
  components: { AdvancedImage },
});
