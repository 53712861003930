
import VotingService from "@/services/VotingService";
import { defineComponent } from "vue";
import { TYPE, useToast } from "vue-toastification";
export default defineComponent({
  data() {
    return {
      description: "",
      name: "",
      autoComplete: null as google.maps.Place | null,
      sessionToken: null,
      placeLat: null,
      placeLong: null,
      address: "",
      placeAddress: "",
      website: "",
    };
  },
  methods: {
    async create() {
      try {
        const toast = useToast();
        if (!this.name || !this.placeAddress || !this.description) {
          toast("Name, address, and description are required", {
            type: TYPE.ERROR,
          });
          return;
        }
        if (!this.placeLat || !this.placeLong) {
          toast("Please enter a valid address", {
            type: TYPE.ERROR,
          });
          return;
        }
        this.$store.dispatch("setLoading", true);
        const response = await VotingService.addVotingOption(
          this.name,
          this.description,
          this.placeAddress,
          this.placeLat,
          this.placeLong,
          this.website,
          this.$route.params.eventId
        );
        const msg = response.data.msg;
        toast(msg, { type: TYPE.SUCCESS });
        this.$emit("create");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },
    initGoogleAPI() {
      // Now safe to use the google object
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      this.initAutocomplete();
    },
    initAutocomplete() {
      const options: google.maps.places.AutocompleteOptions = {
        // componentRestrictions: { country: "us" },
        fields: ["geometry", "formatted_address"],
        strictBounds: false,
      };
      const input = this.$refs.locationSearch as HTMLElement;
      this.autoComplete = new google.maps.places.Autocomplete(
        input as HTMLInputElement,
        options
      );
      this.autoComplete.addListener("place_changed", () => {
        let place = this.autoComplete.getPlace();
        this.placeAddress = place.formatted_address || "";
        this.placeLat = place.geometry?.location.lat();
        this.placeLong = place.geometry?.location.lng();
      });
      this.loading = false;
    },
    async setAddress(i) {
      this.address = i.description;
      this.placesResults = [];
    },
    searchPlaces() {
      this.address = this.autoComplete.getPlace();
      this.placeAddress = this.$refs["locationSearch"].value;
    },
  },
  mounted() {
    try {
      this.$store.dispatch("setLoading", true);
      let scriptSrc = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD3opN_kmymOxAKNHP84pTUgZu3U_XeC9Q&libraries=places`;
      let script: HTMLScriptElement | null =
        document.querySelector(`script[src="${scriptSrc}"]`) ?? null;
      if (!script) {
        script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.src = scriptSrc;
        document.head.appendChild(script);
        script.onload = () => {
          this.initGoogleAPI(); // Initialize Google API related stuff here
        };
      } else {
        this.initAutocomplete();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.dispatch("setLoading", false);
    }
  },
});
