
import { defineComponent, PropType } from "vue";
import { Restaurant } from "../-types";
export default defineComponent({
  props: {
    restaurantDetails: Object as PropType<Restaurant>,
  },
  methods: {
    vote() {
        this.$emit("vote", {type: "restaurant", choice: this.restaurantDetails});
    }
  }
});
