
import { defineComponent } from "vue";
import { $vfm } from "vue-final-modal";
export default defineComponent({
  props: {
    activeIconClass: { type: String, required: true },
    inactiveIconClass: { type: String, required: true },
    label: { type: String, required: true },
    routeName: { type: String, required: true },
    altRouteName: { type: String, required: false },
  },
  methods: {
    async moveToNewRoute() {
      await $vfm.dynamicModals.pop();
      this.$router.push({
        name: this.routeName,
      });
    },
  },
  computed: {
    isActive() {
      return (
        this.$route.name === this.routeName ||
        this.$route.name === this.altRouteName
      );
    },
  },
});
