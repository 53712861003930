import {
  getAuth,
  browserLocalPersistence,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import { Capacitor } from "@capacitor/core";
import { initializeApp, getApp } from "firebase/app";
import { updateAuthorizationHeader } from "@/services/Api";
import store from "@/store/store";
import {
  AuthStateChange,
  FirebaseAuthentication,
} from "@capacitor-firebase/authentication";

const env = process?.env?.NODE_ENV ?? "development";

const firebaseConfig = {
  apiKey: "AIzaSyCrcsYu2rbF97v1p-lwgYGK765SAfTYxc4",
  authDomain: "gophrr.firebaseapp.com",
  projectId: "gophrr",
  storageBucket: "gophrr.appspot.com",
  messagingSenderId: "1013910316773",
  appId: "1:1013910316773:web:cc9e882366e1c4f0f1560c",
  measurementId: "G-EXE8PL1DV8",
};

//initialize the firebase app

const getFirebaseAuth = async () => {
  const app = initializeApp(firebaseConfig);
  if (Capacitor.isNativePlatform()) {
    const auth = initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence,
    });
    FirebaseAuthentication.addListener(
      "authStateChange",
      async (authState: AuthStateChange) => {
        try {
          if (authState.user) {
            const token = (await FirebaseAuthentication.getIdToken())?.token;
            updateAuthorizationHeader(token);
            store.commit("setUserFirebaseUid", authState?.user?.uid);
          }
        } catch (error) {
          console.error("Error in auth state change handler:", error);
        }
      }
    );
    return auth;
  } else {
    const auth = getAuth(app);
    auth.setPersistence(browserLocalPersistence);
    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const token = await user.getIdToken();
          updateAuthorizationHeader(token);
          store.commit("setUserFirebaseUid", user.uid);
        } else {
          store.commit("setUserAlias", null);
          store.commit("setUserFirebaseUid", null);
          store.commit("setSignedInAsGuest", false);
          updateAuthorizationHeader(null);
        }
      } catch (error) {
        console.error("Error in auth state change handler:", error);
      }
    });
    return auth;
  }
};

const initialize = async () => {
  const auth = await getFirebaseAuth();
  if (env === "development") {
    connectAuthEmulator(auth, "http://localhost:9099");
  }
  return auth;
};

export const getAuthInstance = async () => {
  // This ensures the Firebase app is initialized before returning the auth instance
  return await initialize();
};
