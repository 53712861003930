import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import { $vfm } from "vue-final-modal";

const Home = () => import("../components/Home.vue");
const Dashboard = () => import("../components/Dashboard/Dashboard.vue");
const MyTeams = () => import("../components/MyTeams.vue");
const TeamDetails = () => import("../components/Team/TeamDetails.vue");
const EventDetails = () => import("../components/Event/EventDetails.vue");
const EventDetailsChoices = () =>
  import("../components/Event/EventDetailsChoices.vue");
const Login = () => import("../components/Login.vue");
import { RoutingErrors } from "./router-utils";
// const CuisineSelection = () => import("../components/CuisineSelection.vue");
// const SignUp = () => import("../components/SignUp.vue");
// const About = () => import("../components/About.vue");
// const StartSession from "../components/Session/StartSession2.vue";
// const WheelSession = () =>
//   import("../components/Session/WheelSession/WheelSession.vue");
// const ChoiceSession = () =>
//   import("../components/Session/ChoiceSession/ChoiceSession.vue");
// const FoodPreferencesGuestNew from "../components/FoodPreferencesGuest.vue";
// const FoodPreferencesGuestNew = () =>
//   import("../components/GuestWheelSession/FoodPreferencesGuestNew.vue");
// const MyLocations = () => import("../components/MyLocations.vue");
const Venues = () => import("../components/Specials/Venues.vue");
const VenuesRobotsOnly = () =>
  import("../components/Specials/VenuesRobotsOnly.vue");
// const Playground = () => import("../components/Playground.vue");
// const GetStarted = () => import("../components/GetStarted/GetStarted.vue");
const RestaurantSession = () =>
  import("../components/Session/RestaurantSession/RestaurantSession.vue");
const PageNotFound = () => import("../components/PageNotFound.vue");
const PageForbidden = () => import("../components/PageForbidden.vue");
const PageNotAuthorized = () => import("@/components/PageNotAuthorized.vue");
const Privacy = () => import("@/components/Privacy/Privacy.vue");
const Terms = () => import("@/components/Terms/Terms.vue");
const DeleteAccount = () => import("@/components/Account/DeleteAccount.vue");
// const Locations = () => import("@/components/GuestWheelSession/Locations.vue");
const CreateEventWhat = () => import("@/components/Event/CreateEventWhat.vue");
const CreateEventWhen = () => import("@/components/Event/CreateEventWhen.vue");
const CreateEventWhere = () =>
  import("@/components/Event/CreateEventWhere.vue");
const CreateEventWho = () => import("@/components/Event/CreateEventWho.vue");
const EventCalendar = () => import("@/components/Event/EventCalendar.vue");
const CreateEventFinal = () =>
  import("@/components/Event/CreateEventFinal.vue");
// const FoodPreferencesGuest2 = () =>
//   import("@/components/GuestWheelSession/FoodPreferencesGuest2.vue");
import store from "@/store/store";
import { processTeamRedirects } from "@/common/common";
import { Capacitor } from "@capacitor/core";
const CreateSpecial = () =>
  import("@/components/Specials/CreateSpecial/CreateSpecial.vue");
const CreateNonWeeklySpecial = () =>
  import("@/components/Specials/CreateNonWeeklySpecial.vue");
const CreateVenue = () => import("@/components/Specials/CreateVenue.vue");
const VenueDetails = () => import("@/components/Specials/VenueDetails.vue");
const Create = () => import("@/components/Specials/Create.vue");
import { isUserLoggedIn } from "@/store/store";
import { getAxiosInstance } from "@/services/Api";
import { TYPE, useToast } from "vue-toastification";

const ifNotAuthenticated = async (to, from, next) => {
  if (!isUserLoggedIn()) {
    next({ name: "Login" });
    return;
  }

  const ai = await getAxiosInstance();
  if (!ai.defaults.headers.Authorization) {
    try {
      await new Promise<void>((resolve, reject) => {
        const timeout = 30000;
        const interval = 1000;
        let clock = 0;

        const checkAuthorization = setInterval(async () => {
          const axiosInstance = await getAxiosInstance();
          if (axiosInstance.defaults.headers.Authorization) {
            clearInterval(checkAuthorization);
            resolve();
          }
          clock += interval;
          if (clock >= timeout) {
            clearInterval(checkAuthorization);
            reject(new Error(RoutingErrors.TIMEOUT_WAITING_FOR_AUTH));
          }
        }, interval); // Check every second
      });
      next();
    } catch (error) {
      console.log(error);
      const toast = useToast();
      if (
        !navigator.onLine &&
        error.message === RoutingErrors.TIMEOUT_WAITING_FOR_AUTH
      ) {
        toast("You are offline. Please reconnect to a network and try again.", {
          timeout: 5000,
          type: TYPE.ERROR,
        });
        next({ name: to.name });
      } else {
        toast(
          "You have been signed out. Please sign in again. (" + error + ")",
          {
            timeout: 5000,
            type: TYPE.ERROR,
          }
        );
        store.dispatch("logout");
        next({ name: "Login" });
      }
    }
  } else {
    next();
  }
};

const ifLoggedIn = (to, from, next) => {
  if (isUserLoggedIn()) {
    next({ name: "Dashboard" });
    return;
  }
  next();
};

const ifComingFromSiblingCreateEventWizard = (to, from, next) => {
  const siblings = ["Dashboard", "Who", "What", "Where", "When", "Final"];
  if (siblings.includes(from.name)) {
    next();
  } else {
    next("/Dashboard");
  }
};

let routes: Array<RouteRecordRaw> = [
  { name: "Home", path: "/", component: Home, beforeEnter: ifLoggedIn },
  {
    name: "Dashboard",
    path: "/Dashboard",
    component: Dashboard,
    children: [
      //   {
      //     name: "Preferences",
      //     path: "preferences",
      //     component: FoodPreferencesGuest2,
      //   },
      //   { name: "Locations", path: "locations", component: Locations },
      {
        name: "Who",
        path: "who",
        component: CreateEventWho,
        beforeEnter: ifComingFromSiblingCreateEventWizard,
      },
      {
        name: "What",
        path: "what",
        component: CreateEventWhat,
        beforeEnter: ifComingFromSiblingCreateEventWizard,
      },
      {
        name: "Where",
        path: "where",
        component: CreateEventWhere,
        beforeEnter: ifComingFromSiblingCreateEventWizard,
      },
      {
        name: "When",
        path: "when",
        component: CreateEventWhen,
        beforeEnter: ifComingFromSiblingCreateEventWizard,
      },
      {
        name: "Final",
        path: "final",
        component: CreateEventFinal,
        beforeEnter: ifComingFromSiblingCreateEventWizard,
      },
    ],
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "MyTeams",
    path: "/Teams",
    component: MyTeams,
    beforeEnter: ifNotAuthenticated,
  },
  { name: "Venues", path: "/NearbySpecials", component: Venues },
  { name: "VenueDetails", path: "/Venue/:id", component: VenueDetails },
  { name: "ROBOTSONLY", path: "/ROBOTSONLY", component: VenuesRobotsOnly },

  {
    name: "DeleteAccount",
    path: "/Account/DeleteAccount",
    component: DeleteAccount,
    beforeEnter: ifNotAuthenticated,
  },

  //   {
  //     name: "GetStartedMyTeams",
  //     path: "/GetStartedWithTeams",
  //     component: MyTeams,
  //   },
  {
    name: "Login",
    path: "/Login",
    component: Login,
    // meta: { bypassModalClose: true },
  },

  //   {
  //     name: "CuisineSelection",
  //     path: "/CuisineSelection",
  //     component: CuisineSelection,
  //   },
  //   {
  //     name: "GetStartedCuisineSelection",
  //     path: "/GetStartedWithCuisineSelection",
  //     component: CuisineSelection,
  //   },
  // { name: "SignUp", path: "/SignUp", component: SignUp },
  //   {
  //     name: "FoodPreferencesGuest",
  //     path: "/FoodPreferencesGuest",
  //     component: FoodPreferencesGuestNew,
  //   },
  // {
  //   name: "FoodPreferencesGuestNew",
  //   path: "/FoodPreferencesGuestNew",
  //   component: FoodPreferencesGuestNew,
  // },
  //   { name: "About", path: "/About", component: About },

  // {
  //   name: "StartSession",
  //   path: "/Session/:sessionId",
  //   component: StartSession,
  // },
  //   {
  //     name: "WheelSession",
  //     path: "/WheelSession/:sessionId",
  //     component: WheelSession,
  //   },
  //   {
  //     name: "ChoiceSession",
  //     path: "/ChoiceSession/:eventId",
  //     component: ChoiceSession,
  //   },
  {
    path: "/join/:teamUuid/:key",
    name: "JoinTeam",
    beforeEnter: async (to, from, next) => {
      store.commit("pushTeamUuidRedirect", {
        teamUuid: to.params.teamUuid,
        key: to.params.key,
      });

      // Redirect to the login page
      if (isUserLoggedIn() && store.state.userFirebaseUid) {
        await processTeamRedirects();
        next({ name: "Dashboard" });
        return;
      }
      next({ name: "Login" });
    },
    redirect: null,
  },
  {
    name: "RestaurantSession",
    path: "/RestaurantSession/:eventId",
    beforeEnter: ifNotAuthenticated,
    component: RestaurantSession,
  },
  {
    name: "EventDetailsChoices",
    path: "/Choices/:eventId/",
    beforeEnter: ifNotAuthenticated,
    component: EventDetailsChoices,
  },
  // {
  //   name: "MyLocations",
  //   path: "/MyLocations",
  //   component: MyLocations,
  // },
  //   {
  //     name: "GetStartedMyLocations",
  //     path: "/GetStartedWithLocations",
  //     component: MyLocations,
  //   },
  {
    path: "/Events/:eventId",
    name: "EventDetails",
    beforeEnter: ifNotAuthenticated,
    component: EventDetails,
  },
  {
    path: "/Teams/:teamId",
    name: "TeamDetails",
    beforeEnter: ifNotAuthenticated,
    component: TeamDetails,
  },
  {
    path: "/Calendar",
    name: "Calendar",
    beforeEnter: ifNotAuthenticated,
    component: EventCalendar,
  },
  //   {
  //     name: "GetStartedMyEvents",
  //     path: "/GetStartedWithEvents",
  //     component: MyEvents,
  //   },
  {
    name: "Privacy",
    path: "/Privacy",
    component: Privacy,
  },
  {
    name: "Terms",
    path: "/Terms",
    component: Terms,
  },
  //   {
  //     name: "GetStarted",
  //     path: "/GetStarted",
  //     component: GetStarted,
  //   },

  //   {
  //     name: "Playground",
  //     path: "/Playground",
  //     component: Playground,
  //   },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: PageNotFound },

  { path: "/Forbidden", name: "Forbidden", component: PageForbidden },
  { path: "/NotAuthorized", component: PageNotAuthorized },
];

if (process?.env?.NODE_ENV === "development") {
  routes = routes.concat([
    {
      name: "Create",
      path: "/create",
      component: Create,
      children: [
        {
          name: "CreateVenue",
          path: "v",
          component: CreateVenue,
        },
        {
          name: "CreateSpecial",
          path: "s",
          component: CreateSpecial,
        },
        {
          name: "CreateNonWeeklySpecial",
          path: "n",
          component: CreateNonWeeklySpecial,
        },
      ],
    },
  ]);
}

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

// allows the back button to close modals rather than change routes
router.beforeEach((to, from) => {
  if (to.fullPath.includes("firebaseauth/link")) {
    console.log("Blocking external nav to firebase auth...");
    return false;
  }
  if (to.meta.bypassModalClose) {
    // close all modals
    while ($vfm.dynamicModals.length > 0) {
      $vfm.dynamicModals.pop();
    }
  }
  if ($vfm.dynamicModals.length > 0) {
    const lastModal = $vfm.dynamicModals[$vfm.dynamicModals.length - 1];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (lastModal.bind.isNotEscapable !== true) {
      // If the modal is escapable, close it
      $vfm.dynamicModals.pop();
    }
    return false;
  } else {
    store.commit("setLoading", true);
    return true;
  }
});

router.afterEach((to, from) => {
  store.commit("setLoading", false);
});

router.onError(async (e) => {
  console.log(e);
  debugger;
  const platform = Capacitor.getPlatform();
  if (platform === "web") {
    if (!navigator.onLine) {
      await confirm("A network error has occurred. Do you want to reload?");
    } else if (
      await confirm("An update is available. Do you want to reload?")
    ) {
      await window.location.reload();
    }
  } else if (platform === "android" || platform === "ios") {
    if (!navigator.onLine) {
      // show toast
      const toast = useToast();
      toast(
        "A network error has occurred. Please check your internet connection and try again.",
        {
          timeout: 5000,
          type: TYPE.ERROR,
        }
      );
    }
  }
});

export default router;
