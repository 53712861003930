import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fs-4" }
const _hoisted_2 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToWebsite && _ctx.navigateToWebsite(...args))),
    class: "d-flex flex-column text-center badge rounded-circle text-secondary border p-3 my-auto",
    style: {"margin-right":"0.4em","text-decoration":"none"},
    role: "button",
    target: "_blank"
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.day.date()), 1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.day.format("MMM").toUpperCase()), 1)
  ]))
}