import { getAxiosInstance } from "./Api";
import { SessionSelected } from "../types";
import { AxiosResponse } from "axios";

export default {
  // object which allows us to call register method that allows us to hit register endpoint
  async getTeams(userFirebaseUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-teams", {
      userFirebaseUid: userFirebaseUid,
    });
  },
  async joinTeamWithRedirect(
    teamUuid: string,
    userFirebaseUid: string,
    key: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("join-team-with-redirect", {
      teamUuid: teamUuid,
      userFirebaseUid: userFirebaseUid,
      key: key,
    });
  },
  async deleteTeam(
    userFirebaseUid: string,
    teamUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("delete-team", {
      userFirebaseUid: userFirebaseUid,
      teamUid: teamUid,
    });
  },
  async addTeamMember(
    member: string,
    teamUid: string,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("add-team-member", {
      userAlias: member.toLowerCase(),
      teamUid: teamUid,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async removeTeamMember(
    memberUid: string,
    teamUid: string,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("remove-team-member", {
      memberUid: memberUid,
      teamUid: teamUid,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async getTeamMembers(
    teamUid: string,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-team-members", {
      teamUid,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async getTeamOverview(
    teamUid: string,
    userFirebaseUid: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-team-overview", {
      teamUid: teamUid,
      userFirebaseUid: userFirebaseUid,
    });
  },
  async testSendTeamNotification(teamUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("test-send-team-notification", {
      teamUid,
    });
  },
  async createTeam(
    userFirebaseUid: string,
    teamName: string,
    members: string[]
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("create-team", {
      userFirebaseUid,
      teamName,
      members,
    });
  },
  async startSession(
    userFirebaseUid: string,
    teamUid: string,
    radius: number,
    sessionLat: string,
    sessionLong: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("external/start-session", {
      userFirebaseUid: userFirebaseUid,
      uuidPrimaryKey: teamUid,
      radius: radius,
      sessionLat: sessionLat,
      sessionLong: sessionLong,
    });
  },
  async createEvent(
    userFirebaseUid: string,
    teamUid: string,
    sessionLat: string,
    sessionLong: string,
    offset: number,
    radius: number,
    startDateTime: Date,
    restaurantVoteEndDateTime: Date,
    sessionSelected: SessionSelected,
    eventName: string,
    locationNickname: string,
    includeDrinks: boolean,
    includeEvents: boolean,
    includeFood: boolean
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("create-event", {
      userFirebaseUid: userFirebaseUid,
      teamUid,
      sessionLat,
      sessionLong,
      offset,
      radius,
      startDateTime,
      restaurantVoteEndDateTime,
      sessionSelected,
      eventName,
      locationNickname,
      includeDrinks,
      includeEvents,
      includeFood,
    });
  },
  async getTeamUniqueChoices(teamUid: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-team-unique-choices", {
      teamUid,
    });
  },
};
