import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  style: {"font-size":"x-small"},
  class: "text-dark"
}
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = {
  key: 0,
  class: "list-group list-group-flush",
  style: {"padding-top":"1em"}
}
const _hoisted_5 = {
  class: "text-muted position-absolute top-0 start-0",
  style: {"font-size":"small"}
}
const _hoisted_6 = {
  class: "p-2",
  style: {"font-size":"small"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.buttonClass),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showHours = !_ctx.showHours)),
    role: "button",
    type: "button"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("i", {
          class: _normalizeClass(
            _ctx.showHours
              ? 'bi bi-caret-down text-dark font-size-medium px-1'
              : 'bi bi-caret-right-fill text-dark font-size-medium px-1'
          )
        }, null, 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekdays, (d, i) => {
          return (_openBlock(), _createElementBlock("span", {
            key: i,
            class: _normalizeClass(
            _ctx.mappedDays.includes(d)
              ? 'text-secondary fs-5 fw-semibold'
              : 'text-muted fw-light'
          ),
            style: {"user-select":"none"}
          }, _toDisplayString(d[0].toUpperCase()), 3))
        }), 128))
      ]),
      _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.showHours ? "Hide" : "Show") + " hours", 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showHours)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedSpecialDays, (d) => {
              return (_openBlock(), _createElementBlock("li", {
                key: d.day,
                style: {"position":"relative"},
                class: _normalizeClass(
            _ctx.shouldHighlight(d.day)
              ? 'list-group-item rounded-2 fw-bold list-group-item-success p-0'
              : 'list-group-item rounded-2 p-0'
          )
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getDayString(d)), 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getFormattedTimeString(d)), 1)
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}