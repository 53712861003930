
import { defineComponent, PropType } from "vue";
import { SimplifiedChoice } from "../../cuisine/-types";

export default defineComponent({
  props: {
    simplifiedChoice: {
      type: null as PropType<SimplifiedChoice>,
      required: true,
    },
  },
  computed: {
    buttonClass() {
      return this.simplifiedChoice.selected
        ? "border border-3 bg-secondary bg-gradient rounded-pill p-2 m-1 w-100"
        : "border border-1 rounded-pill p-2 m-1 w-100"
        
    },
  },
});
