
import { Special, Vote } from "@/types";
import CalendarDateBadge from "./CalendarDateBadge.vue";
import OtherCalendarDates from "./OtherCalendarDates.vue";
import { PropType, defineComponent } from "vue";
//   import ShowSpecialDays from "./ShowSpecialDays.vue";
import dayjs from "dayjs";
import { weekdays } from "../../common/common";
import { NonWeeklySpecialResponse } from "./-types";
import VotingButton from "../Voting/VotingButton.vue";
import { VoteableItem } from "../Session/RestaurantSession/-.types";

export default defineComponent({
  emits: ["vote"],
  data() {
    const now = dayjs();
    return { now, weekdays };
  },
  props: {
    special: Object as PropType<NonWeeklySpecialResponse>,
    location: String,
    voting: Boolean,
    votes: Array as PropType<Vote[]>,
    shouldShowReducedCalendar: Boolean,
    voteableItem: Object as PropType<VoteableItem>,
    aliasesWhoVotedForThis: Array as PropType<string[]>,
    numberOfVotes: Number,
  },
  methods: { dayjs },
  computed: {
    filteredVotes() {
      // filter votes to pass only special votes that match this special id and pass only the uid
      return this.votes
        .filter((v) => v.choice == this.special.id && v.type === "n")
        .map((v) => v.userFirebaseUid);
    },
    happeningNow(): boolean {
      const now = dayjs();
      return this.special.NonWeeklySpecialDays.some((day) => {
        const start = dayjs(day.start);
        const end = dayjs(day.end);
        return now.isBetween(start, end, null, "[]");
      });
    },
    cPrice(): string {
      if (this.special.price.toUpperCase() === "FREE") {
        return "FREE" + " • ";
      } else if (this.special.price.toUpperCase() === "D") {
        return "";
      } else {
        return "$" + this.special.price + " • ";
      }
    },
  },
  components: { CalendarDateBadge, OtherCalendarDates, VotingButton },
});
