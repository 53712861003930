
import { simplifiedChoices } from "../../cuisine/constants";
import SessionService from "../../services/SessionService";
import { defineComponent } from "vue";
import { TYPE, useToast } from "vue-toastification";
import { Geolocation } from "@capacitor/geolocation";
import SimplifiedChoiceBlock from "../Choice/SimplifiedChoiceBlock.vue";
import {
  createChoices,
  createSimplifiedChoices,
} from "@/cuisine/choiceFactory";
import { Choice } from "@/-types";
import { SimplifiedChoice } from "@/cuisine/-types";
import { updateAlternateChoiceButtonStates } from "./-component";

// import EasyToast from "./EasyToast.vue";

export default defineComponent({
  data() {
    return {
      choices: [] as Choice[],
      simplifiedOrExpanded: "simplified" as "simplified" | "expanded",
      coordinatesLoading: false as boolean,
      loading: false as boolean,
      successMessageVisibility: false,
      coordinateLat: 0,
      coordinateLong: 0,
      lat: null as string | null,
      long: null as string | null,
      radius: "5",
      errorMessage: null,
      simplifiedChoices,
      // toastMessage: "" as string,
      // toastVariant: "danger" as string,
    };
  },
  components: {
    SimplifiedChoiceBlock,
  },
  computed: {
    oppositeOfSimplifiedOrExpanded() {
      return this.simplifiedOrExpanded === "simplified"
        ? "expanded"
        : "simplified";
    },
    setCoordinatesButtonClass() {
      return !this.lat || !this.long
        ? "btn btn-primary w-100 mb-1"
        : "btn btn-outline-primary w-100 mb-1";
    },
    setCoordinatesButtonDisabled() {
      return !!this.lat || !!this.long;
    },
    choicesThirdsChunkSize() {
      return Math.ceil(this.choices.length / 3);
    },
    simplifiedChoicesFirstHalf() {
      return this.simplifiedChoices.slice(0, this.simplifiedChoices.length / 2);
    },
    simplifiedChoicesSecondHalf() {
      return this.simplifiedChoices.slice(
        this.simplifiedChoices.length / 2,
        this.simplifiedChoices.length
      );
    },
    choicesFirstThird() {
      return this.choices.slice(0, this.choicesThirdsChunkSize);
    },
    choicesSecondThird() {
      return this.choices.slice(
        this.choicesThirdsChunkSize,
        Math.ceil(2 * this.choicesThirdsChunkSize)
      );
    },
    choicesThirdThird() {
      return this.choices.slice(
        2 * this.choicesThirdsChunkSize,
        Math.ceil(3 * this.choicesThirdsChunkSize)
      );
    },
  },
  mounted() {
    this.choices = createChoices();
    this.simplifiedChoices = createSimplifiedChoices();
    window.scrollTo(0, 0);
  },
  methods: {
    toggleSimplifiedOrExpanded() {
      this.simplifiedOrExpanded === "simplified"
        ? (this.simplifiedOrExpanded = "expanded")
        : (this.simplifiedOrExpanded = "simplified");
    },
    simplifiedChoiceBlockClicked(simplifiedChoice: SimplifiedChoice) {
      simplifiedChoice.selected = !simplifiedChoice.selected;
      const newStates = updateAlternateChoiceButtonStates(
        this.simplifiedChoices,
        this.choices,
        this.simplifiedOrExpanded,
        simplifiedChoice
      );
      this.simplifiedChoices = newStates["simplified"];
      this.choices = newStates["expanded"];
    },
    async setCoordinates() {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        this.lat = coordinates.coords.latitude;
        this.long = coordinates.coords.longitude;
      } catch (e) {
        console.log(e);
        const toast = useToast();
        toast(
          "Error setting coordinates. Check your permissions and try again.",
          {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          }
        );
        this.errorMessage =
          "Error setting coordinates. Check your permissions and try again.";
      } finally {
        this.loading = false;
      }
    },
    getButtonVariant: function (selected: boolean) {
      return selected
        ? "btn btn-primary btn-sm w-100 mb-1"
        : "btn btn-outline-info btn-sm override-hover w-100 mb-1";
    },
    async startGuestSession() {
      this.loading = true;
      let choiceArray = [];
      for (let i of this.choices) {
        if (i.selected) {
          choiceArray.push(i.keyword);
        }
      }
      try {
        this.errorMessage = "";
        if (
          this.choicesFirstThird.find((c) => c.selected) === undefined &&
          this.choicesSecondThird.find((c) => c.selected) === undefined &&
          this.choicesThirdThird.find((c) => c.selected) === undefined
        ) {
          const toast = useToast();
          toast("Must select at least one choice.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
          this.errorMessage = "Error: No choices selected.";
        } else if (this.radius === "" || this.radius === "0") {
          const toast = useToast();
          toast("Must select a radius that is non-zero.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
          this.errorMessage = "Error: No radius set.";
        } else if (this.lat === null || this.long === null) {
          const toast = useToast();
          toast(
            "No location set. Tap 'Use my current location' and try again.",
            {
              timeout: 5000,
              type: TYPE.ERROR,
              hideProgressBar: true,
            }
          );
          this.errorMessage = "Tap the button above.";
        } else {
          const response = await SessionService.createGuestSession(
            choiceArray,
            this.lat,
            this.long,
            this.radius
          );

          var newSession = response.data.session;
          var newSessionId = newSession.sessionId;

          this.$router.push({
            name: "WheelSession",
            params: { sessionId: newSessionId },
          });
        }
      } catch (error) {
        console.log(error);
        this.errorMessage =
          "Error: The gophrr couldn't find any open restaurants. Try expanding your radius or selecting more cuisine options.";
      } finally {
        this.loading = false;
      }
    },
    setSuccessMessageVisibility(status: boolean) {
      this.successMessageVisibility = status;
    },
  },
});
