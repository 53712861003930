
import { defineComponent } from "vue";
import LocalService from "@/services/LocalService";
import { useToast, TYPE } from "vue-toastification";
import { $vfm } from "vue-final-modal";
export default defineComponent({
  data() {
    return {
      name: "",
      email: "",
      role: "",
      message: "",
      loading: false,
    };
  },
  methods: {
    async saveFeedback() {
      try {
        this.loading = true;
        const isValidEmail =
          /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(this.email);
        const toast = useToast();
        if (this.name === "") {
          toast("Please enter your name.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else if (!isValidEmail) {
          toast("Invalid email.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else if (!this.role) {
          toast("Please select a role.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else if (!this.message) {
          toast("Please enter a message.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else {
          const r = await LocalService.submitFeedback(
            this.name,
            this.email,
            this.role,
            this.message
          );
          if (r.status == 200) {
            toast("Thanks for submitting your feedback.", {
              timeout: 5000,
              type: TYPE.SUCCESS,
              hideProgressBar: true,
            });
            this.$emit("close");
            this.name = this.email = this.role = this.message = "";
            $vfm.dynamicModals.pop();
          } else {
            toast(
              "An error occurred submitting your feedback. Please try again later.",
              {
                timeout: 5000,
                type: TYPE.ERROR,
                hideProgressBar: true,
              }
            );
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
