
import { EventAttendee } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    attendees: Array as PropType<EventAttendee[]>,
  },
  methods: {
    getIcon(status: number) {
      if (status === 1) {
        return "bi bi-check-circle text-success";
      } else if (status === -1) {
        return "bi bi-x-circle text-danger";
      } else {
        return "bi bi-question-circle";
      }
    },
    getListItemClass(status: number) {
      if (status === 1) {
        return "list-group-item align-items-center text-success";
      } else if (status === -1) {
        return "list-group-item align-items-center text-muted";
      } else {
        return "list-group-item align-items-center text-dark";
      }
    },
  },
});
