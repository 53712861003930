
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: { type: String },
  },
  emits: ["remove"],
  methods: {
    deleteItem(n) {
      this.$emit("remove", n);
    },
  },
});
