import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "total-map",
  ref: "totalmap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createElementVNode("button", {
      class: "btn btn-primary w-100 btn-lg m-1",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMore && _ctx.loadMore(...args)))
    }, " Load more... ")
  ]))
}