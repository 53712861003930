
import dayjs, { Dayjs } from "dayjs";
import { PropType, defineComponent } from "vue";
import { NonWeeklySpecialDayResponse } from "./-types";
import "add-to-calendar-button";
export default defineComponent({
  methods: {
    dayjs,
    getMoreDates() {
      this.showing = !this.showing;
      this.$emit("get-other-dates");
    },
  },
  data() {
    return { showing: false };
  },
  props: {
    days: Array as PropType<NonWeeklySpecialDayResponse[]>,
    specialName: String,
    specialDescription: String,
    location: String,
  },
});
