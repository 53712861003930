import { defineComponent } from "vue";

export const showNavButtons = defineComponent({
  data() {
    return {
      windowWidth: window.innerWidth,
      windowWidthIsGreaterThan991px: window.innerWidth > 991,
    };
  },
  computed: {
    showNavButtons(): boolean {
      return this.windowWidth < 991;
    },
  },
  watch: {
    windowWidth(newWidth) {
      // This will be called whenever windowWidth changes
      this.windowWidthIsGreaterThan991px = newWidth > 991;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
});
