import { Choice } from "@/-types";
import { SimplifiedChoice } from "@/cuisine/-types";
import { ChoiceState, ChoicesState } from "./-types";
import { Geolocation } from "@capacitor/geolocation";
import { useToast, TYPE } from "vue-toastification";
import { Coord } from "./-types";
import { useRouter } from "vue-router";
import { generateGuestSessionDetails } from "./-guestWheelSessionFactory";
import SessionService from "@/services/SessionService";
import store from "../../store/store";

import router from "@/router/router";

export function updateAlternateChoiceButtonStates(
  simplifiedChoices: SimplifiedChoice[],
  expandedChoices: Choice[],
  currentState: ChoiceState,
  choiceSelection: Choice | SimplifiedChoice | undefined
): ChoicesState {
  if (currentState === "expanded") {
    // we are in expanded state, need to update the simplified choices
    const choicesSelected = expandedChoices
      .filter((ec) => ec.selected)
      .map((ec) => ec.keyword);
    for (const c of simplifiedChoices) {
      let all = true;
      for (const kw of c.keywords) {
        if (!choicesSelected.includes(kw)) {
          all = false;
        }
      }
      c.selected = all;
    }
  } else if (currentState === "simplified") {
    // we are in simplified state, need to update the expanded choices
    const allKeywords = [];
    for (const sc of simplifiedChoices) {
      if (sc.selected) {
        allKeywords.push(...sc.keywords);
      }
    }
    for (const ec of expandedChoices) {
      ec.selected = allKeywords.includes(ec.keyword);
    }
  } else {
    throw new Error("Invalid State.");
  }
  return { expanded: expandedChoices, simplified: simplifiedChoices };
}

/**
 * @deprecated Import from top level common.ts instead
 */
export async function getCoordinates(): Promise<Coord> {
  try {
    const coordinates = await Geolocation.getCurrentPosition();
    const lat = coordinates.coords.latitude;
    const long = coordinates.coords.longitude;
    return { lat: lat, long: long };
  } catch (e) {
    const toast = useToast();
    toast("Location permission denied.", {
      timeout: 5000,
      type: TYPE.ERROR,
      hideProgressBar: true,
    });
    return { lat: undefined, long: undefined };
  }
}

export const wizardData = generateGuestSessionDetails();

export async function instantiateGuestSessionAndPush(): Promise<
  string | undefined
> {
  try {
    const d = store.state.guestWizardContext;
    const response = await SessionService.createGuestSession(
      d.selectedChoices,
      d.coordinates.lat,
      d.coordinates.long,
      parseInt(d.radius)
    );

    if (response.data.msg === undefined) {
      const newSession = response.data.session;
      const newSessionId = newSession.sessionId;

      router.push({
        name: "WheelSession",
        params: { sessionId: newSessionId },
      });
    } else {
      return response.data.msg;
    }
  } catch (e) {
    console.log(e);
    return "Error: The gophrr couldn't find any open restaurants. Try expanding your radius or selecting more cuisine options.";
  }
}
