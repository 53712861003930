import { EventWizardContext } from "../Wizard/-types";

export function generateEventWizardContext(): EventWizardContext {
  return {
    coordinates: {
      lat: "",
      long: "",
    },
    locationName: "",
    radius: "5",
    moneySigns: { lowerLimit: 0, upperLimit: 5 },
    eventStartDateTime: null,
    restaurantVoteEndDateTime: null,
    sessionType: "team",
    teamId: "",
    teamName: "",
    eventName: "",
    locationNickname: "",
    includeDrinks: true,
    includeFood: true,
    includeEvents: true,
  };
}
