import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container g-1 fluid d-flex flex-column justify-content-between position-relative",
  style: {"height":"100vh","width":"100vw","padding-top":"11vh"},
  id: "main-app"
}
const _hoisted_2 = { class: "d-flex flex-column my-auto mx-auto mt-1 w-100 flex-grow-1 justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_Header = _resolveComponent("Header")!
  const _component_modals_container = _resolveComponent("modals-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PullToRefresh = _resolveComponent("PullToRefresh")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoadingOverlay),
    _createVNode(_component_Header),
    _createVNode(_component_modals_container),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PullToRefresh, {
        refreshable: _ctx.refreshable,
        onRefresh: _ctx.refresh,
        refreshing: _ctx.refreshing,
        shouldReset: _ctx.shouldReset,
        onReset: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shouldReset = false)),
        style: {"min-height":"70vh"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, {
            onRefreshComplete: _ctx.completeRefresh,
            shouldRefresh: _ctx.shouldRefresh,
            onSetRefreshable: _ctx.setRefreshable
          }, null, 8, ["onRefreshComplete", "shouldRefresh", "onSetRefreshable"])
        ]),
        _: 1
      }, 8, ["refreshable", "onRefresh", "refreshing", "shouldReset"]),
      _createVNode(_component_Footer)
    ])
  ]))
}