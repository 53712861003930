import { GuestSessionDetails } from "../Wizard/-types";

export function generateGuestSessionDetails(): GuestSessionDetails {
  return {
    coordinates: {
      lat: "",
      long: "",
    },
    radius: "5",
    moneySigns: { lowerLimit: 0, upperLimit: 5 },
    selectedChoices: [],
  };
}
