import { getAxiosInstance } from "./Api";
import { AxiosResponse } from "axios";
import store from "@/store/store";

export default {
  async getSession(sessionId: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-session", { sessionId });
  },
  async getChatMessages(eventId: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-chat-messages", {
      eventId: eventId,
      userFirebaseUid: store.state.userFirebaseUid,
    });
  },
  async submitRestaurantFeedback(
    token: string,
    opinions: string,
    placeId: string,
    restaurantName: string,
    otherFeedback: string,
    sessionId: number,
    provider: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("submit-restaurant-feedback", {
      token: token,
      opinions,
      placeId,
      restaurantName,
      otherFeedback,
      sessionId,
      provider,
    });
  },
  async createGuestSession(
    choices: string[],
    sessionLat: string,
    sessionLong: string,
    radius: number
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("external/create-guest-session", {
      choices,
      sessionLat,
      sessionLong,
      radius,
    });
  },
};
