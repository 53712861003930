import { renderSlot as _renderSlot, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-group mb-3" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-row flex-wrap mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpSepinput = _resolveComponent("SpSepinput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addInput && _ctx.addInput(...args))),
        autocomplete: "off",
        autocorrect: "off",
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event)),
        enterkeyhint: "enter",
        placeholder: _ctx.placeholder
      }, null, 40, _hoisted_2), [
        [_vModelText, _ctx.text]
      ]),
      _createElementVNode("button", {
        class: "btn btn-primary text-light fw-bolder fs-4",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.checkInputs && _ctx.checkInputs(...args))),
        disabled: !_ctx.text
      }, " + ", 8, _hoisted_3)
    ]),
    _createElementVNode("div", null, [
      (_ctx.acceptedInputs.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptedInputs, (c, i) => {
              return (_openBlock(), _createBlock(_component_SpSepinput, {
                key: i,
                name: c,
                onRemove: _ctx.remove,
                class: "mx-1"
              }, null, 8, ["name", "onRemove"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}