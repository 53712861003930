
import { defineComponent, PropType } from "vue";
import { ChatMessage } from "./-types";
import { timeFromNow } from "@/utilities/dateUtils";

export default defineComponent({
  props: {
    message: {} as PropType<ChatMessage>,
  },
  methods: {
    timeFromNow,
    messageUsername(m: ChatMessage) {
      return m.FirebaseAuthUser.userAlias.toLowerCase() ===
        this.$store.state.userAlias.toLowerCase()
        ? "Me"
        : m.FirebaseAuthUser.userAlias.toLowerCase();
    },
    messageClass(m: ChatMessage) {
      return m.FirebaseAuthUser.userAlias.toLowerCase() ===
        this.$store.state.userAlias.toLowerCase()
        ? "text-dark fw-bolder"
        : "text-success fw-bolder";
    },
    listMessageClass(m: ChatMessage) {
      return m.FirebaseAuthUser.userAlias.toLowerCase() ===
        this.$store.state.userAlias.toLowerCase()
        ? "list-group-item"
        : "list-group-item bg-success-subtle";
    },
    messageAlignment(m: ChatMessage) {
      return m.FirebaseAuthUser.userAlias.toLowerCase() ===
        this.$store.state.userAlias.toLowerCase()
        ? "text-start"
        : "text-start";
    },
  },
});
