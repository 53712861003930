import { MonthDayPicker } from "@/components/Specials/-types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export function timeFromNow(t: Date): string {
  return dayjs(t).fromNow() === "in a few seconds"
    ? "just now"
    : dayjs(t).fromNow();
}

export function getOrdinalSuffix(date) {
  if (!date) {
    return date;
  }
  const j = date % 10,
    k = date % 100;
  if (j == 1 && k != 11) {
    return date + "st";
  }
  if (j == 2 && k != 12) {
    return date + "nd";
  }
  if (j == 3 && k != 13) {
    return date + "rd";
  }
  return date + "th";
}

export function generateDaysOfTheMonth(): MonthDayPicker[] {
  const days: { number: number; selected: boolean }[] = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ number: i, selected: false });
  }
  return days;
}
