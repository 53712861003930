
import { defineComponent } from "vue";
import SpSepinput from "./SpSepInput.vue";
import { stringIsLettersAndNumbersOnly } from "@/utilities/stringChecks";
import { TYPE, useToast } from "vue-toastification";

export default defineComponent({
  data() {
    return { text: "" as string, acceptedInputs: [] as string[] };
  },
  emits: ["updated", "ended", "typing"],
  props: { placeholder: String, forcedTags: Array },
  watch: {
    text(n) {
      if (n !== "") {
        this.$emit("typing");
      } else {
        this.$emit("ended");
      }
    },
    forcedTags(f) {
      this.acceptedInputs = [];
      if (f.length > 0) {
        for (const v of f) {
          this.text = v;
          this.forcePushUpdates(v);
        }
        this.text = "";
      }
    },
  },
  methods: {
    forcePushUpdates() {
      if (!this.acceptedInputs.includes(this.text)) {
        this.acceptedInputs.push(this.text.trim());
        this.updated();
      }
    },
    addInput(e) {
      const code = e.code.toUpperCase();
      if (
        code === "COMMA" ||
        code === "SPACE" ||
        code === "ENTER" ||
        code === "NEXT" ||
        code === "GO" ||
        e.keyCode === 13 ||
        e.keyCode === 188 ||
        e.keyCode === 32
      ) {
        this.checkInputs();
      }
    },
    checkInputs() {
      if (
        stringIsLettersAndNumbersOnly(this.text) &&
        !this.acceptedInputs.includes(this.text)
      ) {
        this.acceptedInputs.push(this.text.trim());
        this.updated();
      } else {
        const toast = useToast();
        toast("Invalid input.", {
          timeout: 3000,
          type: TYPE.ERROR,
          hideProgressBar: true,
        });
      }
      this.text = "";
    },
    remove(n) {
      this.acceptedInputs = this.acceptedInputs.filter((v) => v !== n);
      this.updated();
    },
    updated() {
      this.$emit("updated", this.acceptedInputs);
    },
  },
  components: {
    SpSepinput,
  },
});
