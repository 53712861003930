
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Home" });
    },
    goToSignIn() {
      if (this.$route.name !== "Login") {
        this.$emit("login");
      }
    },
  },
});
