import { updateAuthorizationHeader, getAxiosInstance } from "./Api";
import { AxiosResponse } from "axios";
import { UserCredential, getAuth } from "firebase/auth";
import store from "@/store/store";
import UserService from "./UserService";
import { processTeamRedirects } from "@/common/common";
import { getIdToken } from "firebase/auth";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import { FirebaseError } from "firebase/app";
import { TYPE, useToast } from "vue-toastification";

export async function deleteAllData(
  userFirebaseUid: string
): Promise<AxiosResponse> {
  return (await getAxiosInstance()).post("delete-user-data", {
    userFirebaseUid: userFirebaseUid,
  });
}

export async function refreshCurrentToken(): Promise<string | null> {
  let token = null;
  try {
    if (Capacitor.isNativePlatform()) {
      token = (await FirebaseAuthentication.getIdToken())?.token;
    } else {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user !== null) {
        token = await user.getIdToken();
      }
    }
  } catch (error) {
    console.error("Error in refreshing token:", error);
  }
  return token;
}

export async function setFrontEndDetails(
  res: UserCredential
): Promise<boolean> {
  try {
    if (res) {
      const uid = res.user.uid;
      const token = await getIdToken(res.user);
      store.commit("setUserFirebaseUid", uid);
      updateAuthorizationHeader(token);
      const response = await UserService.getUserAlias(uid);
      const alias = response.data.alias;
      store.commit("setUserAlias", alias.toLowerCase());
      await processTeamRedirects();
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error in setting front-end details:", error);
    // Check if the error is an instance of FirebaseError
    if (error instanceof FirebaseError) {
      // Check for the specific error code
      if (error.code === "auth/network-request-failed") {
        // Handle the network request failed error
        console.error(
          "Network request failed. Please check your internet connection and try again."
        );
        const toast = useToast();
        toast(
          "Network request failed. Please check your internet connection and try again",
          {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          }
        );
      } else {
        // Handle other Firebase errors
        console.error("Firebase error:", error.message);
      }
    } else {
      // Handle non-Firebase errors
      console.error("Non-Firebase error:", error);
    }
    return false;
  }
}

export async function setFrontEndDetailsWithUidAndToken(
  uid: string,
  token: string
): Promise<boolean> {
  try {
    if (uid && token) {
      store.commit("setUserFirebaseUid", uid);
      updateAuthorizationHeader(token);
      const response = await UserService.getUserAlias(uid);
      const alias = response.data.alias;
      store.commit("setUserAlias", alias.toLowerCase());
      await processTeamRedirects();
      return true;
    } else {
      throw new Error("Problem signing in. Please refresh and try again.");
    }
  } catch (error) {
    console.error("Error in setting front-end details:", error);
    return false;
  }
}
