
import { DayOfTheWeek, SpecialDay } from "@/types";
import { PropType, defineComponent } from "vue";
import dayjs from "dayjs";
import { weekdays } from "../../common/common";

export default defineComponent({
  data() {
    const highlight = this.dayToHighlight
      ? this.dayToHighlight
      : weekdays[dayjs().day()].toLowerCase();
    const orderedSpecialDays = [...this.specialDays].sort(
      (a: SpecialDay, b: SpecialDay) =>
        weekdays.findIndex((d1: string) => d1 === a.day) -
        weekdays.findIndex((d2: string) => d2 === b.day)
    );
    return {
      weekdays,
      highlight,
      orderedSpecialDays,
      showHours: false as boolean,
    };
  },
  props: {
    specialDays: Array as PropType<Array<SpecialDay>>,
    dayToHighlight: String as PropType<DayOfTheWeek>,
  },
  computed: {
    buttonClass() {
      return this.showHours
        ? "btn border-grayish border-2 rounded-2 w-75 m-1 px-1"
        : "btn border-grayish border-2 rounded-2 m-1";
    },
    mappedDays() {
      let days = [];
      for (const d of this.specialDays) {
        days.push(d.day);
      }
      return days;
    },
  },
  methods: {
    shouldHighlight(day) {
      return day.toLowerCase() === this.highlight;
    },
    twentyFourHourToAmPm(t: string): string | null {
      return t ? dayjs(`1/1/1 ${t}`).format("h:mmA") : null;
    },
    getDayString(s: SpecialDay): string {
      return (
        s.day.slice(0, 1).toUpperCase() + s.day.slice(1, 3).toLowerCase() + ": "
      );
    },
    getFormattedTimeString(s: SpecialDay): string {
      if (
        (s.startTime === "00:00" && s.endTime === "00:00") ||
        (s.startTime === "00:00:00" && s.endTime === "00:00:00")
      ) {
        return "All day";
      } else {
        return (
          this.twentyFourHourToAmPm(s.startTime) +
          " - " +
          this.twentyFourHourToAmPm(s.endTime)
        );
      }
    },
  },
});
