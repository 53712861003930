
import { default as Header } from "./components/Header2.vue";
import LoadingOverlay from "./components/LoadingOverlay.vue";
import { defineComponent } from "vue";
import "bootstrap";
import PullToRefresh from "./components/PullToRefresh/PullToRefresh.vue";
import Footer from "./components/Footer/Footer.vue";
import eventBus from "./components/EventBus";
import { Capacitor } from "@capacitor/core";
import { App, URLOpenListenerEvent } from "@capacitor/app";
export default defineComponent({
  name: "App",
  data() {
    return {
      refreshing: false,
      shouldRefresh: false,
      shouldReset: false,
      refreshable: false,
      window: {
        width: 0,
        height: 0,
      },
      lastScrollPosition: 0, // Added to track the last scroll position
    };
  },
  mounted() {
    const platform = Capacitor.getPlatform();
    const native = Capacitor.isNativePlatform();
    if (native) {
      if (platform === "android") {
        App.addListener("backButton", () => this.$router.back());
      }
      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        // Example url: https://www.gophrr.io/tabs/tabs2
        // slug = /tabs/tabs2
        const slug = event.url.split(".io").pop();
        // We only push to the route if there is a slug present
        if (slug) {
          this.$router.push({
            path: slug,
          });
        }
      });
    }

    window.addEventListener("scroll", this.emitScrollDirection);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    setRefreshable(refreshable: boolean) {
      this.refreshable = refreshable;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    refresh() {
      this.refreshing = true;
      this.shouldRefresh = true;
    },
    completeRefresh() {
      this.shouldRefresh = false;
      this.refreshing = false;
      this.shouldReset = true;
    },
    emitScrollDirection() {
      const currentScrollPosition = window.scrollY;
      const direction =
        currentScrollPosition > this.lastScrollPosition ? "down" : "up";
      eventBus.$emit("mainScrollDirection", direction);
      this.lastScrollPosition = currentScrollPosition; // Update lastScrollPosition
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.emitScrollDirection);
    window.removeEventListener("resize", this.handleResize);
    if (Capacitor.isNativePlatform()) {
      App.removeAllListeners();
    }
  },
  components: {
    Header,
    LoadingOverlay,
    PullToRefresh,
    Footer,
  },
});
