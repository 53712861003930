
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    const refreshPullDistance = 150;
    return {
      startY: 0,
      pullDistance: 0,
      refreshPullDistance,
    };
  },

  props: {
    refreshing: { type: Boolean, required: true },
    refreshable: { type: Boolean, required: true },
    shouldReset: { type: Boolean, required: true },
  },
  computed: {
    shouldShowContinueToRefresh() {
      return !this.refreshing && this.pullDistance > 0 && window.scrollY === 0;
    },
    refreshClass() {
      if (this.pullDistance > this.refreshPullDistance) {
        return "h3 bi bi-arrow-clockwise";
      } else {
        return "h3 bi bi-arrow-down";
      }
    },
    wordToRefresh() {
      if (this.pullDistance > this.refreshPullDistance) {
        return "Release";
      } else {
        return "Pull";
      }
    },
  },
  watch: {
    refreshing() {
      if (this.refreshing) {
        this.pullDistance = 50;
      } else {
        this.pullDistance = 0;
      }
    },
    shouldReset(n, o) {
      if (n) {
        this.pullDistance = 0;
        this.$emit("reset");
      }
    },
  },
  methods: {
    touchStart(event) {
      if (!this.refreshable || window.scrollY > 0 || this.refreshing) {
        return;
      }
      if (event.touches[0].clientY) {
        this.startY = event.touches[0].clientY;
      }
    },
    touchMove(event) {
      if (!this.refreshable || this.refreshing || window.scrollY > 0) {
        return;
      }
      const currentY = event.touches[0].clientY;
      if (currentY < this.startY) {
        return;
      }
      this.pullDistance = currentY - this.startY;
    },
    touchEnd(event) {
      //   debugger;
      if (this.refreshable) {
        // If not at the top of the page or already refreshing, return early
        if (window.scrollY > 0 || this.refreshing) {
          this.pullDistance = 0;
          return;
        }
        const endY = event.changedTouches[0].clientY;
        if (endY < this.startY) {
          // user scrolled up
          this.startY = 0;
          this.pullDistance = 0;
          return;
        }
        this.pullDistance = endY - this.startY;

        if (this.pullDistance > this.refreshPullDistance) {
          // refreshPullDistance is the threshold for triggering refresh
          this.$emit("refresh");
        } else {
          this.pullDistance = 0;
        }
      }
    },
    refreshDone() {
      this.pullDistance = 0;
    },
  },
  emits: ["refresh", "reset"],
});
