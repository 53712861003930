import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00b4b232"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-row flex-wrap text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_to_calendar_button = _resolveComponent("add-to-calendar-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "btn btn-grayish m-2 w-50 fw-bolder",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getMoreDates && _ctx.getMoreDates(...args)))
    }, _toDisplayString(_ctx.showing ? "Hide " : "Show all") + " dates... ", 1),
    (_ctx.showing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day) => {
            return (_openBlock(), _createBlock(_component_add_to_calendar_button, {
              key: day.id,
              name: _ctx.specialName,
              description: _ctx.specialDescription,
              options: "'Apple','Google','iCal','Outlook.com'",
              location: _ctx.location,
              startDate: _ctx.dayjs(day.start).format('YYYY-MM-DD'),
              endDate: _ctx.dayjs(day.end).format('YYYY-MM-DD'),
              startTime: _ctx.dayjs(day.start).format('HH:mm'),
              endTime: _ctx.dayjs(day.end).format('HH:mm'),
              timeZone: "America/New_York",
              label: _ctx.dayjs(day.start).format('MM/DD/YY'),
              buttonStyle: "date",
              size: "5",
              class: "text-success"
            }, null, 8, ["name", "description", "location", "startDate", "endDate", "startTime", "endTime", "label"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}