import axios, { AxiosInstance, AxiosError } from "axios";
import store from "@/store/store";
import router from "../router/router";
import { TYPE, useToast } from "vue-toastification";
import { Capacitor } from "@capacitor/core";
import { refreshCurrentToken } from "./AuthenticationService";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAuth } from "firebase/auth";

let axiosInstancePromise: Promise<AxiosInstance>;

const updateGlobalAuthorizationHeader = (token: string | null) => {
  if (!axiosInstance) {
    console.log("axiosInstance is not initialized yet.");
    return;
  }
  // Update the Authorization header
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // If the token is null, remove the Authorization header
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest: AxiosError) => {
  const newToken = await refreshCurrentToken();
  if (newToken) {
    updateAuthorizationHeader(newToken);
    updateGlobalAuthorizationHeader(newToken);
    // Update the authorization header in the original request configuration
    failedRequest.response.config.headers[
      "Authorization"
    ] = `Bearer ${newToken}`;
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};

async function setupAxiosInstance() {
  const env = process?.env?.NODE_ENV ?? "development";
  let baseURL = `https://gophrr-app.herokuapp.com/`;
  if (env === "development") {
    baseURL =
      Capacitor.getPlatform() === "android"
        ? `https://192.168.1.200/`
        : `http://localhost:8081/`;
  }
  const axiosInstance = axios.create({
    baseURL: baseURL,
  });

  return axiosInstance;
}

// Wrap the setup in an async function
async function initializeAxiosInstance() {
  if (!axiosInstancePromise) {
    axiosInstancePromise = setupAxiosInstance().then((axiosInstance) => {
      createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
        pauseInstanceWhileRefreshing: true,
      });

      axiosInstance.interceptors.response.use(
        undefined,
        async function (error: AxiosError) {
          if (error?.response) {
            const errStatus = error.response.status;
            if (errStatus == 403) {
              if (error.response.data.shouldLogOut) {
                const toast = useToast();
                toast("You have been signed out. Please sign in again.", {
                  timeout: 5000,
                  type: TYPE.ERROR,
                });
                store.dispatch("logout", true);
              } else {
                router.push({ name: "Forbidden" });
              }
            } else if (errStatus == 400) {
              const toast = useToast();
              toast(error.response.data.error, {
                timeout: 5000,
                type: TYPE.ERROR,
              });
            } else if (errStatus == 500) {
              const toast = useToast();
              toast("Server error. Try again later.", {
                timeout: 5000,
                type: TYPE.ERROR,
              });
            } else if (errStatus == 404) {
              router.push({ name: "NotFound" });
            }
          }
          return Promise.reject(error);
        }
      );

      return axiosInstance;
    });
  }
  return axiosInstancePromise;
}

// Call the async function to initialize axiosInstance
let axiosInstance;
initializeAxiosInstance().then((instance) => {
  axiosInstance = instance;
});

/**
 * Updates the axiosInstance Authorization header.
 * @param token The new token to set in the Authorization header. If null, clears the Authorization header.
 */
export const updateAuthorizationHeader = async (
  token: string | null
): Promise<void> => {
  const instance = await getAxiosInstance();
  if (!instance) {
    console.log("axiosInstance is not initialized yet.");
    return;
  }
  // Update the Authorization header
  if (token) {
    instance.defaults.headers["Authorization"] = `Bearer ${token}`;
  } else {
    // If the token is null, remove the Authorization header
    delete instance.defaults.headers["Authorization"];
  }
};

// Export a function to get the initialized axiosInstance
export const getAxiosInstance = async (): Promise<AxiosInstance> => {
  return initializeAxiosInstance();
};
