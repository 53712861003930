import { Choice } from "@/-types";
import { deepCopySerializableArray } from "@/utilities/object";
import { SimplifiedChoice } from "./-types";
import { choices, simplifiedChoices } from "./constants";
export function choice(
  keyword: string,
  name: string,
  selected: boolean
): Choice {
  return {
    keyword: keyword,
    name: name,
    selected: selected,
  };
}

export function getChoiceNameFromKeyword(choiceKeyword: keyof Choice): string {
  return choices.find((c) => c.keyword === choiceKeyword).name;
}

export function createChoices(): Choice[] {
  return deepCopySerializableArray(choices);
}

export function createSimplifiedChoices(): SimplifiedChoice[] {
  return deepCopySerializableArray(simplifiedChoices);
}
