import { defineComponent } from "vue";
import { isUserLoggedIn } from "../store/store";

export const userStatusMixin = defineComponent({
  computed: {
    isUserLoggedIn() {
      return isUserLoggedIn();
    },
  },
});
