
import TeamsService from "@/services/TeamsService";
import { Team } from "@/types";
import dayjs from "dayjs";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  props: { teamId: { type: String, required: true } },
  async created() {
    try {
      this.$store.commit("setLoading", true);
      const r = await TeamsService.getTeamOverview(
        this.teamId,
        this.$store.state.userFirebaseUid
      );
      this.team = r.data.team;
    } catch (e) {
      console.error(e);
    } finally {
      this.$store.commit("setLoading", false);
    }
  },
  data() {
    return { team: {} as Team };
  },
  computed: {
    creatorAlias() {
      if (!this.team) return "";
      return (
        this.team?.members?.find((m) => m.uid === this.team.creator)?.alias ??
        "Unknown"
      );
    },
  },
  methods: { dayjs },
});
