
import { PropType, defineComponent } from "vue";
import { googleUrl, instagramUrl, sendGtagEvent } from "../../common/common";
import { getDistanceFromLatLonInMiles } from "../../utilities/distanceCalcs";
import Special from "./Special.vue";
import { Share } from "@capacitor/share";
import {
  showVenueFeedbackModal,
  showVenueImagesModal,
} from "../Modals/ModalController";
import dayjs from "dayjs";
import NonWeeklySpecial from "./NonWeeklySpecial.vue";

// import CloudinaryImage from "../Cloudinary/CloudinaryImage.vue";

export default defineComponent({
  props: {
    venue: Object,
    lat: Number,
    long: Number,
    markedVenues: Array,
    searchTerm: String,
    mode: String as PropType<"weekly" | "non-weekly" | "details">,
    noMaps: Boolean,
    showTags: Boolean,
  },
  methods: {
    instagramUrl,
    showVenueImagesModal,
    dayjs,
    reportIssueWithVenue() {
      const title =
        "Report issue with listing for " +
        this.venue.name +
        " (Id #" +
        this.venue.id +
        ")";
      showVenueFeedbackModal(title, this.venue);
    },
    getType(type) {
      if (type === "w") {
        return "Special";
      } else if (type === "n") {
        return "NonWeeklySpecial";
      } else {
        throw new Error("Invalid type");
      }
    },
    async shareLink() {
      sendGtagEvent(this.$gtag, "referral_sent", {
        eventLabel: "sent_a_referral_to_" + this.venue.id,
        event_category: "engagement",
      });
      await Share.share({
        title: "gophrr.io",
        text: "Check out this place on gophrr.io",
        url: "https://www.gophrr.io/Venue/" + this.venue.id,
        dialogTitle: "Share",
      });
    },
    // copyLinkToClipboard() {
    //   navigator.clipboard.writeText(
    //     "https://www.gophrr.io/Venue/" + this.venue.id
    //   );
    //   const toast = useToast();
    //   toast("Copied link to clipboard!", {
    //     timeout: 5000,
    //     type: TYPE.INFO,
    //     hideProgressBar: true,
    //   });
    // },
    addOrRemoveFromMap() {
      this.$emit("addOrRemoveFromMap", this.venue.id);
    },
    calculatedDistance(placeLat, placeLong) {
      if (this.lat && this.long) {
        return getDistanceFromLatLonInMiles(
          +placeLat,
          +placeLong,
          +this.lat,
          +this.long
        );
      } else {
        return "";
      }
    },
    googleUrl,
  },
  computed: {
    lastVerifiedDate() {
      if (this.venue.specials.length > 0) {
        return dayjs(
          new Date(
            Math.min(
              ...this.venue.specials.map((s) => new Date(s.lastVerified))
            )
          )
        );
      }
      return dayjs(new Date(this.venue.lastVerified));
    },
    addOrRemoveFromMapClass(): string {
      return this.venueIsNotMarked
        ? ""
        : "position-absolute top-0 start-100 translate-middle badge rounded-pill fw-bold bg-danger text-light";
    },
    addOrRemoveFromMapSymbol(): string {
      return this.venueIsNotMarked ? "" : "-";
    },
    venueIsNotMarked() {
      return (
        this.markedVenues.findIndex((mv) => mv.id === this.venue.id) === -1
      );
    },
  },
  emits: ["addOrRemoveFromMap"],
  components: { Special, NonWeeklySpecial },
});
