
import { defineComponent } from "vue";
import L, { Map } from "leaflet";
import { useToast, TYPE } from "vue-toastification";
import { MILES_TO_METERS, getCoordinates } from "../../common/common";
import CannotGetLocationWarning from "../CannotGetLocationWarning.vue";
import LocalService from "@/services/LocalService";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "../../css/leaflet-overrides/overrides.css";
import "leaflet.smooth_marker_bouncing";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import markerIcon from "../../assets/map_marker.png";
import { showVenueDetailsModal } from "../Modals/ModalController";
import { Capacitor } from "@capacitor/core";
var mileRange = document.querySelector(".form-range") as HTMLInputElement;

function iosPolyfill(e: TouchEvent) {
  // Fixes error on iOS where the slider doesn't update
  if (!mileRange) return;
  const rect = mileRange.getBoundingClientRect();
  const val = (e.touches[0].clientX - rect.left) / (rect.right - rect.left);
  const max = parseFloat(mileRange.getAttribute("max"));
  const segment = 1 / (max - 1);
  const segmentArr = Array.from({ length: max + 1 }, (_, i) => segment * i);
  const closestSegment = segmentArr.reduce((prev, curr) =>
    Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev
  );

  mileRange.value = (segmentArr.indexOf(closestSegment) + 1).toString();
}

function isIOS() {
  return (
    Capacitor.getPlatform() === "ios" ||
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream)
  );
}

export default defineComponent({
  components: { CannotGetLocationWarning },
  props: {
    radius: Number,
    latLong: Object,
  },

  async mounted() {
    if (window.innerWidth < 800) {
      this.tapOrClick = "Tap";
    }

    this.markerClusterGroup = L.markerClusterGroup();
    const r = await LocalService.getLatLongs();
    this.allMarkers = r.data.venueLatLongs;
    for (let i = 0; i < this.allMarkers.length; i++) {
      const marker = this.allMarkers[i];
      const newMarker = L.marker([marker.lat, marker.long]);
      const specials = (marker.Specials ?? [{ name: "No active specials" }])
        .map(function (s) {
          return "<li>" + s.name + "</li>";
        })
        .reduce((a, b) => {
          return a + b;
        }, ""); // Provide an initial value to the reduce method
      const popupLinkId = `popup-link-${i}`; // Generate a unique id for each anchor tag
      newMarker.bindPopup(
        `<div><span>${marker.name}</span><ul>${specials}</ul><a class='text-decoration-underline text-primary' type='button' role='button' id='${popupLinkId}'>Show Venue Details</a></div>`
      );
      newMarker.on("popupopen", function () {
        const popupLink = document.getElementById(popupLinkId);
        if (popupLink) {
          popupLink.addEventListener("click", function (e) {
            e.preventDefault();
            showVenueDetailsModal(marker.id);
          });
        }
      });
      this.markerClusterGroup.addLayer(newMarker);
    }
    // todo adjust this so it isn't always at the same place by default
    const lat =
      this.latLong.lat ?? 37.54157308281214 + (Math.random() * 0.2 - 0.1);
    const long =
      this.latLong.long ?? -77.43634401987207 + (Math.random() * 0.2 - 0.1);
    this.map = new Map("map", { attributionControl: false }).setView(
      [lat, long],
      this.DEFAULT_ZOOM
    );
    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
    ).addTo(this.map);
    this.localLatLong.lat = lat;
    this.localLatLong.long = long;
    this.dropPin(null);
    this.map.on("click", this.dropPin);
    this.map.addLayer(this.markerClusterGroup);
    var mileRange = document.querySelector(".form-range");

    if (isIOS()) {
      mileRange.addEventListener("touchend", iosPolyfill, {
        passive: true,
      });
    }
  },
  data() {
    const customIcon = new L.Icon({
      iconUrl: markerIcon,
      iconSize: [40, 40],
      iconAnchor: [16, 30],
      popupAnchor: [-12, 0],
    });
    const DEFAULT_ZOOM = 10;
    return {
      customIcon,
      allMarkers: [],
      DEFAULT_ZOOM,
      searchMiles: this.radius ?? 10,
      searchCircle: null,
      tapOrClick: "Click",
      map: null,
      layerGroup: null,
      loading: false as boolean,
      localLatLong: { lat: null, long: null },
      tryingToLoadAgain: false as boolean,
      failedToGetCoordinates: false as boolean,
      markerClusterGroup: null,
    };
  },
  beforeUnmount() {
    this.map.removeEventListener("click");
  },
  methods: {
    dropPin(event) {
      if (event === null || event.latlng) {
        if (this.layerGroup) {
          this.layerGroup.clearLayers();
        }
        this.layerGroup = L.layerGroup();
        this.layerGroup.addTo(this.map);
      }
      if (event === null) {
        L.marker([this.localLatLong.lat, this.localLatLong.long], {
          icon: this.customIcon,
        })
          .addTo(this.layerGroup)
          .bounce(5);
        this.searchCircle = L.circle(
          [this.localLatLong.lat, this.localLatLong.long],
          {
            color: "dodgerblue",
            fillColor: "#2497fb",
            fillOpacity: 0.15,
            opacity: 0.5,
            radius: this.searchMiles * MILES_TO_METERS,
          }
        ).addTo(this.layerGroup);
        this.map.setView(
          [this.localLatLong.lat, this.localLatLong.long],
          this.DEFAULT_ZOOM
        );
      } else if (event.latlng) {
        L.marker([event.latlng.lat, event.latlng.lng], {
          icon: this.customIcon,
        })
          .addTo(this.layerGroup)
          .bounce(5);
        this.searchCircle = L.circle([event.latlng.lat, event.latlng.lng], {
          color: "dodgerblue",
          fillColor: "#2497fb",
          fillOpacity: 0.15,
          opacity: 0.5,
          radius: this.searchMiles * MILES_TO_METERS,
        }).addTo(this.layerGroup);
        this.localLatLong.lat = event.latlng.lat;
        this.localLatLong.long = event.latlng.lng;
        this.map.setView(this.searchCircle.getLatLng(), this.map.getZoom());
      }
    },
    setLatLong(event) {
      event.stopPropagation();
      if (this.localLatLong.lat && this.localLatLong.long) {
        this.$emit("setCoordinates", this.localLatLong);
      } else {
        const toast = useToast();
        toast(this.tapOrClick + " the map to drop a pin", {
          timeout: 5000,
          type: TYPE.INFO,
          hideProgressBar: true,
        });
      }
    },
    async getCurrentLocationAndSetLatLong(event) {
      event.stopPropagation();
      this.loading = true;
      this.failedToGetCoordinates = false;
      this.tryingToLoadAgain = false;
      const toast = useToast();
      if (Capacitor.getPlatform() === "ios") {
        toast("Getting location (can take up to 10 seconds)...", {
          timeout: 5000,
          type: TYPE.DEFAULT,
          hideProgressBar: true,
        });
      }
      const ll = await getCoordinates();
      if (ll.lat && ll.long) {
        this.localLatLong = { lat: ll.lat, long: ll.long };
        this.dropPin(null);
      } else {
        toast("Unable to get location.", {
          timeout: 5000,
          type: TYPE.ERROR,
          hideProgressBar: true,
        });
        this.failedToGetCoordinates = true;
      }
      this.loading = false;
    },
  },
  watch: {
    searchMiles(newMiles) {
      if (this.searchCircle) {
        this.searchCircle.setRadius(newMiles * MILES_TO_METERS); // convert miles to meters
        this.map.setView(this.searchCircle.getLatLng(), this.map.getZoom());
        this.$emit("updateRadius", +newMiles);
      }
    },
  },
  emits: ["setCoordinates", "updateRadius"],
});
