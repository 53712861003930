
import { defineComponent } from "vue";
import UserService from "../services/UserService";
import {} from "googlemaps";
import { UserLocation } from "@/-types";
import { TYPE, useToast } from "vue-toastification";

export default defineComponent({
  data() {
    return {
      loading: true as boolean,
      placeLat: "" as string,
      placeLong: "" as string,
      placeNickname: "" as string,
      placeAddress: "" as string,
      savedPlaces: [] as UserLocation[],
      autoComplete: null as google.maps.Place | null,
      address: "" as string,
      showReturnToGetStartedButton: false as boolean,
    };
  },
  computed: {
    locationsContainerClass() {
      return this.$route.path === "/Dashboard"
        ? "col-12 text-center"
        : "col-sm-12 col-lg-8 text-center";
    },
    addLocationDisabled(): boolean {
      const duplicates = this.savedPlaces.filter(
        (sp) =>
          sp.nickname == this.placeNickname || sp.address == this.placeAddress
      );
      return (
        !this.loading &&
        this.placeNickname &&
        this.placeAddress &&
        duplicates.length != 0
      );
    },
  },
  mounted() {
    try {
      this.$store.dispatch("setLoading", true);
      this.getUserLocations();
      let scriptSrc = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD3opN_kmymOxAKNHP84pTUgZu3U_XeC9Q&libraries=places`;
      let script: HTMLScriptElement | null =
        document.querySelector(`script[src="${scriptSrc}"]`) ?? null;
      if (!script) {
        script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.src = scriptSrc;
        document.head.appendChild(script);
        script.onload = this.initAutocomplete;
      } else {
        this.initAutocomplete();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.dispatch("setLoading", false);
    }
  },
  methods: {
    initAutocomplete() {
      const options: google.maps.places.AutocompleteOptions = {
        // componentRestrictions: { country: "us" },
        fields: ["formatted_address", "geometry"],
        strictBounds: false,
      };
      const input = this.$refs.locationSearch as HTMLElement;
      this.autoComplete = new google.maps.places.Autocomplete(
        input as HTMLInputElement,
        options
      );
      this.autoComplete.addListener("place_changed", () => {
        let place = this.autoComplete.getPlace();
        this.placeAddress = place.formatted_address || "";
        this.placeLat = place.geometry.location.lat();
        this.placeLong = place.geometry.location.lng();
      });
      this.loading = false;
    },
    searchPlaces() {
      this.address = this.autoComplete.getPlace();
    },
    async getUserLocations() {
      try {
        const response = await UserService.getUserLocations(
          this.$store.state.userFirebaseUid
        );
        this.savedPlaces = response.data.locations;
      } catch (error) {
        console.log(error);
        //TODO handle errors
        // this.error = error.response.data.error;
      }
    },
    async addUserLocation() {
      this.placeAddress = this.$refs["locationSearch"].value;
      const duplicates = this.savedPlaces.filter(
        (sp) =>
          sp.nickname == this.placeNickname || sp.address == this.placeAddress
      );
      try {
        if (duplicates.length > 0) {
          const toast = useToast();
          toast(
            "You've already created a location with that name or address.",
            {
              timeout: 5000,
              type: TYPE.ERROR,
              hideProgressBar: true,
            }
          );
          throw new Error("DuplicateAddressOrNickname");
        }
        if (
          this.placeLat === null ||
          this.placeLong === null ||
          this.placeLat === undefined ||
          this.placeLong === undefined ||
          this.placeLat === "" ||
          this.placeLong === ""
        ) {
          const toast = useToast();
          toast("Malformed input location. Check the address and try again.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else {
          const response = await UserService.addUserLocation(
            this.$store.state.userFirebaseUid,
            this.placeNickname,
            this.placeAddress,
            this.placeLat,
            this.placeLong
          );
          if (response.status === 200) {
            this.$store.commit("setUserLocations", response.data.locations);
            this.$store.commit("setLocationContext", response.data.location);
            this.$store.commit(
              "setEventWizardLocationNicknameContext",
              response.data.location.nickname
            );
            this.$emit("close");
          }
        }
      } catch (e) {
        console.log(e);
        //TODO handle errors
        // this.error = error.response.data.error;
      }
    },
    async deleteUserLocation(address: string, name: string) {
      try {
        if (
          await confirm(
            "This will delete " + name + " at " + address + ". Are you sure?"
          )
        ) {
          await UserService.deleteUserLocation(
            this.$store.state.userFirebaseUid,
            address
          );
        }
      } catch (error) {
        console.log(error);
        //TODO handle errors
        // this.error = error.response.data.error;
      } finally {
        this.getUserLocations();
      }
    },
  },
});
