
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      localUserAlias: this.$store.state.userAlias,
    };
  },
  computed: {
    canUpdate() {
      return this.$store.state.userAlias !== this.localUserAlias;
    },
  },
  methods: {
    updateUserAlias() {
      this.$emit("update", this.localUserAlias);
    },
  },
});
