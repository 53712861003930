
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    goToSignIn() {
      if (this.$route.name !== "Login") {
        this.$emit("login");
      }
    },
  },
});
