import { $vfm } from "vue-final-modal";
import ComponentWrapperModal from "./ComponentWrapperModal.vue";
import FoodPreferencesGuestNew from "../GuestWheelSession/FoodPreferencesGuestNew.vue";
import MyLocations from "../MyLocations.vue";
import Title from "./Title.vue";
import CreateNewTeam from "../Team/CreateNewTeam.vue";
import eventBus from "../EventBus";
import AddVotingOption from "../Voting/AddVotingOption.vue";
import DropAPinModal from "../Locations/DropAPinModal.vue";
import { Coord, Venue, VenueImage } from "../../types";
import Attendees from "../Event/Attendees.vue";
import RestaurantDetails from "../Session/RestaurantSession/RestaurantDetails.vue";
import { Restaurant } from "../Session/-types";
import store from "@/store/store";
import MapOfSpecials from "../Specials/MapOfSpecials.vue";
import VenueImages from "../Specials/VenueImages.vue";
import Feedback from "../Specials/Feedback.vue";
import { ChatMessage } from "../Chat/-types";
import VotingRules from "../Session/VotingRules.vue";
import Chat from "../Chat/Chat.vue";
import TeamOverview from "../Team/TeamOverview.vue";
import router from "@/router/router";
import GuestWarning from "../Account/GuestWarning.vue";
import UpdateUserAliasWarning from "../Account/UpdateUserAliasWarning.vue";
import GuestCannotVote from "../Account/GuestCannotVote.vue";
import UserService from "@/services/UserService";
import { TYPE, useToast } from "vue-toastification";
import VenueDetails from "../Specials/VenueDetails.vue";
import VenueFeedback from "../Specials/VenueFeedback.vue";

export function showFoodPreferencesGuestModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "FoodPreferencesGuestNew",
      classes: ["col-sm-12", "col-md-8", "mx-auto", "overflow-auto"],
      escToClose: true,
      clickToClose: false,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Find Food Nearby",
        },
      },
      default: {
        component: FoodPreferencesGuestNew,
      },
    },
  });
}

export function showMapOfSpecialsModal(
  latLong: Coord,
  markedVenues: Venue[]
): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "MapOfSpecials",
      classes: ["col-sm-12", "col-md-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Nearby Places",
        },
      },
      default: {
        component: MapOfSpecials,
        bind: {
          latLong: latLong,
          markedVenues: markedVenues,
        },
        on: {
          load() {
            eventBus.$emit("loadMoreVenues");
          },
        },
      },
    },
  });
}

export function showMyLocationsModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      async cancel(close: () => void) {
        if (store.state.locationContext.nickname === "new-location") {
          store.commit("setLocationContext", {
            nickname: null,
            placeLat: null,
            placeLong: null,
            address: null,
          });
        }
        eventBus.$emit("locations-done");
        await $vfm.hideAll();
        close();
      },
    },
    bind: {
      componentName: "MyLocations",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
      clickToClose: false,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Manage locations",
        },
      },
      default: {
        component: MyLocations,
        on: {
          async close() {
            // TODO refactor as this is hacky
            if (store.state.locationContext.nickname === "new-location") {
              store.commit("setLocationContext", {
                nickname: null,
                placeLat: null,
                placeLong: null,
                address: null,
              });
            }
            eventBus.$emit("locations-done");
            await $vfm.hideAll();
          },
        },
      },
    },
  });
}

export function showCreateNewTeamModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "CreateNewTeam",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
      clickToClose: false,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Create a new team",
        },
      },
      default: {
        component: CreateNewTeam,
        on: {
          async close() {
            // TODO refactor as this is hacky
            await $vfm.hideAll();
            eventBus.$emit("team-created");
          },
        },
      },
    },
  });
}

export function showAttendeesModal(attendees: string[]): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "Attendees",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Attendees",
        },
      },
      default: {
        component: Attendees,
        bind: {
          attendees: attendees,
        },
      },
    },
  });
}

export function showVotingRulesModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        store.commit("setSeenVotingRules", true);
        close();
      },
    },

    bind: {
      componentName: "VotingRules",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Rules for the Vote",
        },
      },
      default: {
        component: VotingRules,
      },
    },
  });
}

export function showAddVotingOptionModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },

    bind: {
      componentName: "AddVotingOption",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Add a Poll Option",
        },
      },
      default: {
        component: AddVotingOption,
        on: {
          async create() {
            eventBus.$emit("voting-option-created");
            await $vfm.hideAll();
          },
        },
      },
    },
  });
}

export function showGuestWarningModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },

    bind: {
      componentName: "GuestWarning",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Warning!",
        },
      },
      default: {
        component: GuestWarning,
        on: {
          login() {
            Promise.resolve($vfm.hideAll()).then(() => {
              router.push({ name: "Login" });
            });
          },
        },
      },
    },
  });
}

export function showGuestCannotVoteModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },

    bind: {
      componentName: "GuestCannotVote",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Log in to vote",
        },
      },
      default: {
        component: GuestCannotVote,
        on: {
          login() {
            Promise.resolve($vfm.hideAll()).then(() => {
              router.push({ name: "Login" });
            });
          },
        },
      },
    },
  });
}

export function showDropAPinModal(latLong, radius): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        eventBus.$emit("setLatLong", { lat: null, long: null });
        close();
      },
    },

    bind: {
      componentName: "DropAPinModal",
      isNotEscapable: true,
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Search Specials",
        },
      },
      default: {
        component: DropAPinModal,
        bind: {
          latLong: latLong,
          radius: radius,
        },
        on: {
          async setCoordinates(l) {
            const coords = l;
            eventBus.$emit("setLatLong", coords);
            await $vfm.hideAll();
          },
          updateRadius(r) {
            eventBus.$emit("updateRadius", r);
          },
        },
      },
    },
  });
}

export function showVenueDetailsModal(venueId: string): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },

    bind: {
      componentName: "VenueDetails",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Venue Details",
        },
      },
      default: {
        component: VenueDetails,
        bind: {
          venueId: venueId,
          noNav: true,
        },
      },
    },
  });
}

export function showHasSeenUpdateUserAliasModal(): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        store.commit("setHasSeenUpdateUserAliasWarning", true);
        close();
      },
    },

    bind: {
      componentName: "UpdateUserAliasWarning",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "",
        },
      },
      default: {
        component: UpdateUserAliasWarning,
        on: {
          async update(u: string) {
            const userAlias = u.toLowerCase();
            const response = await UserService.updateUserAlias(
              store.state.userFirebaseUid,
              userAlias
            );
            if (response && response.status === 200) {
              const toast = useToast();
              toast(response.data.msg, {
                timeout: 2500,
                type: TYPE.DEFAULT,
                hideProgressBar: true,
              });
              await store.commit("setUserAlias", userAlias);
              //   hacky but works
              eventBus.$emit("alias-updated");
              await $vfm.hideAll();
            }
          },
          cancel() {
            store.commit("setHasSeenUpdateUserAliasWarning", true);
            $vfm.hideAll();
          },
        },
      },
    },
  });
}

// export function showEventCalendarModal(events: Event[]): void {
//   $vfm.show({
//     component: ComponentWrapperModal,
//     on: {
//       cancel(close: () => void) {
//         close();
//       },
//       push(eventId: number) {
//         router.push({ name: "EventDetails", params: { eventId: eventId } });
//       },
//     },

//     bind: {
//       componentName: "EventCalendar",
//       classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
//       escToClose: true,
//     },
//     slots: {
//       title: {
//         component: Title,
//         bind: {
//           text: "Event Calendar",
//         },
//       },
//       default: {
//         component: EventCalendar,
//         bind: {
//           events: events,
//         },
//       },
//     },
//   });
// }

export function showTeamOverviewModal(teamId: string): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },

    bind: {
      componentName: "TeamOverview",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Team Overview",
        },
      },
      default: {
        component: TeamOverview,
        bind: {
          teamId: teamId,
        },
      },
    },
  });
}

export function showChatModal(eventId: string, messages: ChatMessage[]): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        // fixes an issue where the live updates would not reconnect after closing
        eventBus.$emit("connect");
        close();
      },
    },
    bind: {
      componentName: "Chat",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Chat",
        },
      },
      default: {
        component: Chat,
        bind: {
          eventId: eventId,
          messages: messages,
        },
      },
    },
  });
}

export function showRestaurantDetailsModal(
  restaurantDetails: Restaurant
): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "RestaurantDetails",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
      clickToClose: false,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Restaurant Details",
        },
      },
      default: {
        component: RestaurantDetails,
        bind: {
          restaurantDetails: restaurantDetails,
        },
        on: {
          async vote(e) {
            // TODO refactor as this is hacky
            await $vfm.hideAll();
            eventBus.$emit("vote", e);
          },
        },
      },
    },
  });
}

export function showVenueImagesModal(images: VenueImage[], id: number): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "VenueImages",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "overflow-auto"],
      escToClose: true,
      clickToClose: false,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: "Photos - Powered by Yelp",
        },
      },
      default: {
        component: VenueImages,
        bind: {
          images: images,
          id: id,
        },
      },
    },
  });
}

export function showFeedbackModal(title: string | undefined): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "Feedback",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "my-auto", "overflow-auto"],
      escToClose: true,
      clickToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: title ?? "Contact Us",
        },
      },
      default: {
        component: Feedback,
        bind: {},
        on: {
          async close() {
            $vfm.dynamicModals.pop();
          },
        },
      },
    },
  });
}

export function showVenueFeedbackModal(title: string, venue: Venue): void {
  $vfm.show({
    component: ComponentWrapperModal,
    on: {
      cancel(close: () => void) {
        close();
      },
    },
    bind: {
      componentName: "VenueFeedback",
      classes: ["col-sm-12", "col-lg-8", "mx-auto", "my-auto", "overflow-auto"],
      escToClose: true,
      clickToClose: true,
    },
    slots: {
      title: {
        component: Title,
        bind: {
          text: title ?? "Contact Us",
        },
      },
      default: {
        component: VenueFeedback,
        bind: { venue: venue },
        on: {
          async close() {
            $vfm.dynamicModals.pop();
          },
        },
      },
    },
  });
}
