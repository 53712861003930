import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_3 = { class: "fw-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(_ctx.listMessageClass(_ctx.message))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.messageClass(_ctx.message))
        }, _toDisplayString(_ctx.messageUsername(_ctx.message)), 3),
        _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.timeFromNow(_ctx.message.createdAt)), 1)
      ]),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.messageAlignment(_ctx.message))
      }, _toDisplayString(_ctx.message.message), 3)
    ])
  ], 2))
}