
import { defineComponent } from "vue";
import eventBus from "../EventBus";

export default defineComponent({
  emits: ["confirm", "cancel"],
  methods: {
    cancel() {
      this.$emit("cancel", close);
      eventBus.$emit("modal-closed");
    },
  },
  inheritAttrs: false,
  props: {
    componentName: String,
    clickToClose: Boolean,
    showCloseButton: { type: Boolean, default: true },
    // force user to close the modal manually rather than allow back button or route change to close
    isNotEscapable: { type: Boolean, default: false },
  },
});
