import { getAxiosInstance } from "./Api";
import { AxiosResponse } from "axios";
import { Day, SearchTerm, SimpleTimeSearchSlot, Tag } from "@/types";
import { SpecialsForm } from "@/components/Specials/-types";
import { EventBrowseMode } from "../types";
export default {
  async searchForVenuesByName(search: string): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("search-for-venues-by-name", {
      search: search,
    });
  },
  async getLatLongs(): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-lat-longs");
  },
  async createNonWeeklySpecial(s: SpecialsForm): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("create-non-weekly-special", {
      s: s,
    });
  },
  async getLocalVenuesAndSpecials(
    mode: EventBrowseMode,
    radius: number,
    lat: number,
    long: number,
    pageNumber: number,
    searchTerms: SearchTerm[],
    search: string,
    searchTime: SimpleTimeSearchSlot | undefined,
    offset: number | undefined
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-local-venues-and-specials", {
      mode: mode,
      radius: radius,
      lat: lat,
      long: long,
      pageNumber: pageNumber,
      searchTerms: searchTerms,
      search: search,
      searchTime: searchTime,
      timeOffsetFromUTC: offset ?? null,
    });
  },
  async deleteSpecial(id: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("delete-special", {
      specialId: id,
    });
  },
  async deleteVenue(id: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("delete-venue", {
      venueId: id,
    });
  },
  async validateSpecial(id: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("validate-special", {
      specialId: id,
    });
  },
  async lookupVenueDetailsById(id: number): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("get-venue-details", {
      id: id,
    });
  },
  async createSpecial(
    id: number,
    days: Day[],
    name: string,
    description: string,
    type: string,
    price: string,
    validUntil: Date,
    holiday: string,
    coverCost: number,
    tags: string[],
    specialIdToEdit: string | null,
    imageUrls: string[]
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("create-special", {
      id: id,
      days: days,
      name: name,
      description: description,
      type: type,
      price: price,
      validUntil: validUntil,
      holiday: holiday,
      coverCost: coverCost,
      tags: tags,
      specialIdToEdit: specialIdToEdit,
      imageUrls: imageUrls,
    });
  },
  async lookupVenueDetailsByGooglePlaceId(
    googlePlaceId: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post(
      "lookup-venue-details-by-google-place-id",
      {
        googlePlaceId: googlePlaceId,
      }
    );
  },
  async lookupVenuesAndSpecials(): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("lookup-venues-and-specials");
  },
  async lookupVenueIds(term: string | undefined): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("lookup-venue-ids", { term: term });
  },
  async submitFeedback(
    name: string,
    email: string,
    role: string,
    message: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("submit-feedback", {
      name: name,
      email: email,
      role: role,
      message: message,
    });
  },
  async notifyMeAboutSpecials(
    email: string,
    coordinates: { lat: number | string; long: number | string }
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("notify-me-about-specials", {
      email: email,
      coordinates: coordinates,
    });
  },
  async createVenue(
    name: string,
    description: string,
    address: string,
    lat: number,
    long: number,
    googlePlaceId: string,
    website: string,
    phone: string,
    venueTags: Tag[],
    yelpPlaceId: string,
    instagramHandle: string
  ): Promise<AxiosResponse> {
    return (await getAxiosInstance()).post("create-venue", {
      name: name,
      description: description,
      address: address,
      lat: lat,
      long: long,
      googlePlaceId: googlePlaceId,
      website: website,
      phone: phone,
      venueTags: venueTags,
      yelpPlaceId: yelpPlaceId,
      instagramHandle: instagramHandle,
    });
  },
};
