
import { DayOfTheWeek, Special, Vote } from "@/types";
import { PropType, defineComponent } from "vue";
import ShowSpecialDays from "./ShowSpecialDays.vue";
import dayjs from "dayjs";
import { weekdays } from "../../common/common";
import VotingButton from "../Voting/VotingButton.vue";
import Overlay from "../Overlay.vue";
import CloudinaryImage from "../Cloudinary/CloudinaryImage.vue";
import { VoteableItem } from "../Session/RestaurantSession/-.types";

export default defineComponent({
  emits: ["vote"],
  data() {
    const dayToHighlight = this.highlightDay ? this.highlightDay : null;
    const now = dayjs();
    const basePath = "Specials/";
    return { now, weekdays, basePath, dayToHighlight };
  },
  props: {
    special: Object as PropType<Special>,
    voting: Boolean,
    voteableItem: Object as PropType<VoteableItem>,
    aliasesWhoVotedForThis: Array as PropType<string[]>,
    numberOfVotes: Number,
    highlightDay: String as PropType<DayOfTheWeek>,
  },

  computed: {
    imageUrl() {
      return this.special.SpecialImages.length > 0
        ? this.special.SpecialImages[0]?.imagePath
        : "";
    },
    happeningNow(): boolean {
      const dayIndex = this.now.day();
      const day = weekdays[dayIndex].toLowerCase();
      const specialDays = this.special.SpecialDays.filter(
        (sp) => sp.day === day
      );
      for (const specialDay of specialDays) {
        const startTime = dayjs()
          .hour(specialDay.startTime.slice(0, 2))
          .minute(specialDay.startTime.slice(3, 5));
        const endTime = dayjs()
          .hour(specialDay.endTime.slice(0, 2))
          .minute(specialDay.endTime.slice(3, 5));
        const startTimeIsAllDay =
          startTime.format("HH:mm") === "00:00" ||
          startTime.format("HH:mm:ss") === "00:00:00";
        const endTimeIsAllDay =
          endTime.format("HH:mm") === "00:00" ||
          endTime.format("HH:mm:ss") === "00:00:00";
        if (
          (startTime < this.now && endTime > this.now) ||
          (startTimeIsAllDay && endTimeIsAllDay)
        ) {
          return true;
        }
      }
      return false;
    },
    cPrice(): string {
      if (this.special.price.toUpperCase() === "FREE") {
        return "FREE" + " • ";
      } else if (this.special.price.toUpperCase() === "D") {
        return "";
      } else {
        return "$" + this.special.price + " • ";
      }
    },
  },
  components: {
    ShowSpecialDays,
    VotingButton,
    Overlay,
    CloudinaryImage,
  },
});
