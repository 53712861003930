
import { defineComponent } from "vue";
export default defineComponent({
  props: { images: Array, id: Number },
  computed: {
    firstImage() {
      return this.images[0] ?? [];
    },
    otherImages() {
      return this.images.slice(1) ?? [];
    },
  },
});
