import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "position-absolute top-0 end-0" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm position-absolute",
  style: {"font-size":"0.5rem","left":"23%","top":"23%"}
}
const _hoisted_3 = {
  key: 1,
  class: "position-absolute text-danger fw-bolder",
  style: {"font-size":"0.5rem","left":"23%","top":"23%"}
}
const _hoisted_4 = {
  key: 2,
  class: "badge border border-light rounded-circle bg-secondary position-absolute top-0 start-100 translate-middle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "btn position-relative p-0",
      role: "button",
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.vote && _ctx.vote(...args)))
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2))
        : (!_ctx.heartClass.includes('fill'))
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, "Vote"))
          : _createCommentVNode("", true),
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.heartClass),
        style: {"color":"red"}
      }, null, 2),
      (_ctx.someVotes)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.numberOfVotes), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}