
import LocalService from "@/services/LocalService";
import { defineComponent } from "vue";
import Venue from "./Venue.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ogImage from "@/assets/ogimg.png";
import { sendGtagEvent } from "@/common/common";
export default defineComponent({
  head() {
    return {
      title: this.venue?.name
        ? "Specials at " + this.venue?.name
        : "Specials on gophrr",
      meta: [
        {
          name: "description",
          content:
            "gophrr helps you find specials and plan events. View nearby deals, schedule events with friends and more. ",
        },
        {
          property: "og:description",
          content:
            "Happy hours, specials, and more on gophrr" + this.venue?.name
              ? " at " + this.venue?.name
              : "",
        },
        {
          property: "og:title",
          content:
            "Check out specials " + this.venue?.name
              ? "at " + this.venue?.name
              : "on gophrr...",
        },
        {
          property: "og:image",
          content: "https://gophrr.io/" + ogImage,
        },
        {
          property: "og:url",
          content: "https://gophrr.io",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: "gophrr",
        },
      ],
    };
  },
  methods: {
    goToSeeVenues() {
      sendGtagEvent(this.$gtag, "wants_to_see_more_specials", {
        eventLabel: "referred_user_sees_more_specials",
        event_category: "engagement",
      });

      this.$router.push({ name: "Venues" });
    },
  },
  async created() {
    this.loading = true;
    const response = await LocalService.lookupVenueDetailsById(this.id);
    this.venue = response.data.venue;
    this.loading = false;
    this.loaded = true;

    sendGtagEvent(this.$gtag, "looking_at_venue", {
      eventLabel: "looking_at_" + this.venue.id,
      event_category: "engagement",
    });
  },
  props: {
    venueId: String,
    noNav: Boolean,
  },
  data() {
    let vId = this.$route.params?.id;
    if (!vId) {
      vId = this.venueId;
    }
    return {
      id: vId,
      loading: false,
      loaded: false,
      venue: null,
    };
  },
  components: {
    Venue,
  },
});
