
import { defineComponent } from "vue";
import L, { Map } from "leaflet";
import eventBus from "../EventBus";
import router from "@/router/router";
import { $vfm } from "vue-final-modal";
import { showVenueDetailsModal } from "../Modals/ModalController";

export default defineComponent({
  props: {
    latLong: Object,
    markedVenues: Array,
  },
  mounted() {
    this.createMap();
    this.createMapItems();
    this.modalShown();
    eventBus.$on("refreshMap", (venues) => {
      this.displayVenues = venues;
      this.createMapItems();
    });
  },
  beforeUnmount() {
    eventBus.$off("refreshMap");
  },
  data() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const gophrr = require("../../assets/gophrr.svg");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const drink = require("../../assets/drink.svg");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const event = require("../../assets/event.svg");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const food = require("../../assets/food.svg");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const drinkAndEvent = require("../../assets/drink_and_event.svg");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const drinkAndFood = require("../../assets/drink_and_food.svg");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const eventAndFood = require("../../assets/event_and_food.svg");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const drinkEventAndFood = require("../../assets/drink_and_event_and_food.svg");

    return {
      gophrr,
      drink,
      event,
      food,
      drinkAndEvent,
      drinkAndFood,
      eventAndFood,
      drinkEventAndFood,
      map: null,
      layerGroup: L.layerGroup(),
      loading: false as boolean,
      displayVenues: this.markedVenues,
    };
  },
  methods: {
    createMap() {
      this.map = new Map("total-map").setView(
        [this.latLong.lat, this.latLong.long],
        13
      );
    },
    createMapItems() {
      this.layerGroup = L.layerGroup();
      L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      ).addTo(this.map);

      L.marker([this.latLong.lat, this.latLong.long])
        .addTo(this.layerGroup)
        .bindPopup("<span>Your search location</span>");

      if (this.displayVenues.length > 0) {
        for (const v of this.displayVenues) {
          let i = this.gophrr;
          const specialTypes = v.specials.map((s) => s.type);
          if (
            specialTypes.includes("DEF") ||
            (specialTypes.includes("D") &&
              specialTypes.includes("E") &&
              specialTypes.includes("F"))
          ) {
            i = this.drinkEventAndFood;
          } else if (
            specialTypes.includes("DE") ||
            (specialTypes.includes("D") && specialTypes.includes("E"))
          ) {
            i = this.drinkAndEvent;
          } else if (
            specialTypes.includes("EF") ||
            specialTypes.includes("E" && specialTypes.includes("F"))
          ) {
            i = this.eventAndFood;
          } else if (
            specialTypes.includes("DF") ||
            (specialTypes.includes("D") && specialTypes.includes("F"))
          ) {
            i = this.drinkAndFood;
          } else if (specialTypes.includes("D")) {
            i = this.drink;
          } else if (specialTypes.includes("E")) {
            i = this.event;
          } else if (specialTypes.includes("F")) {
            i = this.food;
          }
          const specials = v.specials
            .map(function (s) {
              return "<li>" + s.name + "</li>";
            })
            .reduce((a, b) => {
              return a + b;
            });
          let icon = L.icon({
            iconUrl: i,
            iconSize: [40, 40],
            iconAnchor: [4, 20],
          });
          const popupLinkId = `popup-link-${v.id}`; // Generate a unique id for each anchor tag
          const newMarker = L.marker([v.lat, v.long], { icon: icon });
          newMarker.on("popupopen", function () {
            const popupLink = document.getElementById(popupLinkId);
            if (popupLink) {
              popupLink.addEventListener("click", function (e) {
                e.preventDefault();
                showVenueDetailsModal(v.id + "");
              });
            }
          });
          newMarker
            .bindPopup(
              `<div><span>${v.name}</span><ul>${specials}</ul><a class='text-decoration-underline text-primary' type='button' role='button' id='${popupLinkId}'>Show Details</a></div>`
            )
            .addTo(this.layerGroup);
        }
        this.layerGroup.addTo(this.map);
      }
    },
    modalShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    loadMore() {
      this.$emit("load");
    },
  },
});
