
import { defineComponent } from "vue";
import NavButton from "./NavButton.vue";
import { userStatusMixin } from "@/mixins/isUserLoggedInMixin";
import { showNavButtons } from "@/mixins/showNavButtonsMixin";
export default defineComponent({
  mixins: [userStatusMixin, showNavButtons],
  components: { NavButton },
  computed: {
    buttonsToShow(): Array<any> {
      return this.isUserLoggedIn
        ? [
            {
              label: "Home",
              inactiveIconClass: "bi bi-house-door",
              activeIconClass: "bi bi-house-door-fill text-primary",
              routeName: "Home",
              altRouteName: "Dashboard",
            },
            {
              label: "Specials",
              inactiveIconClass: "bi bi-star",
              activeIconClass: "bi bi-star-fill text-primary",
              routeName: "Venues",
              altRouteName: null,
            },
            {
              label: "My Events",
              inactiveIconClass: "bi bi-calendar",
              activeIconClass: "bi bi-calendar-fill text-primary",
              routeName: "Calendar",
              altRouteName: null,
            },
            {
              label: "My Teams",
              inactiveIconClass: "bi bi-people",
              activeIconClass: "bi bi-people-fill text-primary",
              routeName: "MyTeams",
              altRouteName: null,
            },
          ]
        : [
            {
              label: "Home",
              inactiveIconClass: "bi bi-house-door",
              activeIconClass: "bi bi-house-door-fill text-primary",
              routeName: "Home",
              altRouteName: "Dashboard",
            },
            {
              label: "Specials",
              inactiveIconClass: "bi bi-star",
              activeIconClass: "bi bi-star-fill text-primary",
              routeName: "Venues",
              altRouteName: null,
            },
            {
              label: "Sign In",
              inactiveIconClass: "bi bi-box-arrow-right",
              activeIconClass: "bi bi-box-arrow-right text-primary",
              routeName: "Login",
              altRouteName: null,
            },
          ];
    },
  },
});
