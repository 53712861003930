import App from "./App.vue";
// import 'es6-promise/auto';
import router from "./router/router";
import store from "./store/store";
import { tooltip } from "./bootstrap-shims/tooltip";
import { io } from "socket.io-client";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast, { PluginOptions, TYPE } from "vue-toastification";
import vfmPlugin from "vue-final-modal";
import Vue3TouchEvents from "vue3-touch-events";
import { VueHeadMixin, createHead } from "@unhead/vue";
import { getAuthInstance } from "./firebase/config";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "./scss/custom.scss";

// add localized format to dayjs
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
import AOS from "aos";
import { createApp } from "vue";
import { Capacitor } from "@capacitor/core";
import VueGtag from "vue-gtag";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(duration);

const options: PluginOptions = {
  // Vue Toastification options go here
  maxToasts: 3,
  transition: "Vue-Toastification__fade",
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      closeButtonClassName: "text-dark",
      hideProgressBar: true,
    },
    [TYPE.SUCCESS]: {
      closeButtonClassName: "text-dark",
      hideProgressBar: true,
    },
    [TYPE.INFO]: {
      closeButtonClassName: "text-dark",
      hideProgressBar: true,
    },
    [TYPE.DEFAULT]: {
      closeButtonClassName: "text-dark",
      hideProgressBar: true,
    },
    [TYPE.WARNING]: {
      closeButtonClassName: "text-dark",
      hideProgressBar: true,
    },
  },
};

AOS.init();

const env = process?.env?.NODE_ENV ?? "development";

let socket;
if (Capacitor.getPlatform() === "android" && env === "development") {
  socket = io("https://192.168.1.200:443", {
    transports: ["websocket", "polling"],
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity,
  });
} else if (env === "development") {
  socket = io("http://localhost:8081/", {
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity,
  });
} else {
  socket = io("https://gophrr-app.herokuapp.com/", {
    withCredentials: true,
    extraHeaders: {
      "gophrr-socket-header": "abcd",
    },
    forceNew: true,
    transports: ["websocket", "polling"],
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity,
    rememberUpgrade: true,
  });
}

// Note: This is for when the Vue error handler doesn't kick in, otherwise, handled by the vue error handler.
window.onerror = function (mMsg, mSource, mLineNo, mColNo, error) {
  // mMsg = Char. Error Msg: i.e. "Uncaugh SyntaxError: Unexpected token '<'"
  // mSource = Char. i.e. 'https://yoursite.com/js/chunk-431587f6.ff603bf5.js'
  // mLineNo = Numeric. Line no
  // mColNo = Numeric. Col no
  // error = Object. Error object
  if (
    typeof mMsg === "string" &&
    mMsg.toLowerCase().indexOf("unexpected token '<'") > -1
  ) {
    // this happens when a new update gets applies but my router.js file hasn't been pulled down for whatever reason. A page refresh fixes it.
    // mSource =
    if (navigator.onLine) {
      window.location.reload();
    }
  }
}; // window.onerror

const app = createApp(App);
const head = createHead();

async function initApp() {
  const a = await getAuthInstance();
  app.use(head);
  app.mixin(VueHeadMixin);
  app.config.globalProperties.$socketio = socket;

  app.component("Datepicker", Datepicker);
  app.use(Toast, options);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  app.use(vfmPlugin);
  app.use(router);
  if (env !== "development") {
    app.use(
      VueGtag,
      {
        appName: "gophrr",
        pageTrackerScreenviewEnabled: true,
        config: { id: "G-9RWN94B1GE" },
      },
      router
    );
  } else {
    console.log("Not using gtag in development mode");
  }
  app.use(Vue3TouchEvents);
  app.use(store);
  app.directive("tooltip", tooltip);
  app.mount("#app");
}

initApp();
