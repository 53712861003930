
import dayjs, { Dayjs } from "dayjs";
import { PropType, defineComponent } from "vue";
export default defineComponent({
  props: { day: Object as PropType<Dayjs>, website: String },
  methods: {
    navigateToWebsite() {
      if (this.website) {
        const url =
          this.website.startsWith("http://") ||
          this.website.startsWith("https://")
            ? this.website
            : "https://" + this.website;
        window.open(url, "_blank");
      }
    },
  },
});
