import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "position-fixed w-100",
  style: {"bottom":"0rem","left":"0rem","z-index":"10001"}
}
const _hoisted_2 = { class: "btn-group bg-light border border-top d-flex justify-content-evenly rounded-0 pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavButton = _resolveComponent("NavButton")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonsToShow, (button) => {
        return (_openBlock(), _createBlock(_component_NavButton, {
          key: button.label,
          label: button.label,
          inactiveIconClass: button.inactiveIconClass,
          activeIconClass: button.activeIconClass,
          routeName: button.routeName,
          altRouteName: button.altRouteName
        }, null, 8, ["label", "inactiveIconClass", "activeIconClass", "routeName", "altRouteName"]))
      }), 128))
    ])
  ], 512)), [
    [_vShow, !_ctx.windowWidthIsGreaterThan991px]
  ])
}