
import { defineComponent, PropType } from "vue";
import { ChatMessage } from "./-types";
import ChatMessageVue from "./ChatMessage.vue";

export default defineComponent({
  data() {
    return { newMessage: "" as string };
  },
  mounted() {
    this.setupChatScroller();
    this.$refs.chatScrollContainer.scroll({
      top: this.$refs.chatScrollContainer.scrollHeight,
      behavior: "smooth",
    });
  },
  props: {
    eventId: String,
    messages: Array as PropType<Array<ChatMessage>>,
  },
  computed: {
    reactiveMessages() {
      return this.messages;
    },
  },
  methods: {
    async waitATick() {
      await this.$nextTick();
    },
    setupChatScroller() {
      this.$socketio.on("chat_message", () => {
        this.waitATick();
        if (this.$refs.chatScrollContainer) {
          this.$refs.chatScrollContainer.scroll({
            top: this.$refs.chatScrollContainer.scrollHeight,
            behavior: "smooth",
          });
        } else {
          //TODO - ref is being destroyed, fix later
        }
      });
    },
    async sendMessage(e): Promise<void> {
      e.preventDefault();
      if (this.newMessage) {
        this.$socketio.emit("chat_message", {
          userFirebaseUid: this.$store.state.userFirebaseUid,
          message: this.newMessage,
          userAlias: this.$store.state.userAlias.toLowerCase(),
          eventId: this.eventId,
        });
      }
      this.newMessage = "";
    },
  },
  components: { ChatMessageVue },
});
