import store from "../store/store";
import UserService from "@/services/UserService";
import TeamsService from "@/services/TeamsService";
import LocalService from "@/services/LocalService";
import dayjs from "dayjs";
import { useToast, TYPE } from "vue-toastification";
import {
  Team,
  Coord,
  UserLocation,
  TeamsEventsAndLocations,
  RestaurantData,
  SpecialDay,
  SearchTerm,
  VenuesSearchResult,
  RestaurantAndVoteResponse,
  SimpleTimeSearchSlot,
  VoteType,
} from "../types";
import { Geolocation } from "@capacitor/geolocation";
import EventService from "@/services/EventService";
import { UserCredential } from "firebase/auth";
import { setFrontEndDetailsWithUidAndToken } from "@/services/AuthenticationService";
import router from "@/router/router";
import { Share } from "@capacitor/share";
import { EventBrowseMode } from "@/types";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { isUserLoggedIn } from "../store/store";

export async function getTeams(): Promise<Team[] | []> {
  try {
    if (isUserLoggedIn()) {
      const response = await TeamsService.getTeams(store.state.userFirebaseUid);
      return response?.data?.teams ?? [];
    }
    return [];
  } catch (error) {
    if (error) console.error(error);
    return [];
  }
}

export async function getLocations(): Promise<UserLocation[] | []> {
  try {
    if (isUserLoggedIn()) {
      const response = await UserService.getUserLocations(
        store.state.userFirebaseUid
      );
      return response?.data?.locations ?? [];
    }
    return [];
  } catch (error) {
    if (error) console.error(error);
  }
}

export async function getTeamsLocationsAndEvents(): Promise<TeamsEventsAndLocations> {
  try {
    if (isUserLoggedIn()) {
      const response = await UserService.refreshUserData(
        store.state.userFirebaseUid
      );
      return {
        events: response.data.events ?? [],
        locations: response.data.locations ?? [],
        teams: response.data.teams ?? [],
      };
    }
  } catch (error) {
    if (error) console.error(error);
  }
}

export async function getCoordinates(): Promise<Coord> {
  try {
    const coordinates = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    });
    const lat = coordinates.coords.latitude ?? undefined;
    const long = coordinates.coords.longitude ?? undefined;
    return { lat: lat, long: long };
  } catch (e) {
    return { lat: undefined, long: undefined };
  }
}

export async function getLocalVenuesAndSpecials(
  mode: EventBrowseMode,
  radius: number,
  coords: Coord,
  pageNumber: number | undefined,
  searchTerms: SearchTerm[],
  search: string,
  timeSearch: SimpleTimeSearchSlot | undefined,
  offset?: number | undefined
): Promise<VenuesSearchResult> {
  try {
    if (coords.lat !== undefined && coords.long !== undefined) {
      const response = await LocalService.getLocalVenuesAndSpecials(
        mode,
        radius,
        coords.lat,
        coords.long,
        pageNumber ?? 1,
        searchTerms,
        search,
        timeSearch,
        offset ?? null
      );
      return {
        venues: response.data.venues ?? [],
        nonWeeklyVenues: response.data.nonWeeklyVenues ?? [],
        noMore: response.data.noMore,
      };
    }
  } catch (error) {
    if (error) console.error(error);

    return {
      venues: [],
      nonWeeklyVenues: [],
      noMore: true,
    };
  }
}

export function getRandomCoordinatesAroundRichmond() {
  return {
    lat: 37.54157308281214 + (Math.random() * 0.2 - 0.1),
    long: -77.43634401987207 + (Math.random() * 0.2 - 0.1),
  };
}

export const MILES_TO_METERS = 1609.34;

export function updateStoreTeamsLocationsAndEvents(
  e: TeamsEventsAndLocations
): void {
  store.dispatch("setUserLocations", e.locations);
  store.dispatch("setUserTeams", e.teams);
  store.dispatch("setUserEvents", e.events);
}

export async function getRestaurants(
  eventId: number
): Promise<RestaurantData[]> {
  try {
    if (isUserLoggedIn()) {
      const response = await EventService.loadRestaurants(
        eventId,
        store.state.userFirebaseUid
      );
      return response.data.restaurants ?? [];
    }
  } catch (error) {
    if (error) console.error(error);
  }
}

export async function getRestaurantsAndVotes(
  eventId: number | string,
  extended = false,
  requestMore = false
): Promise<RestaurantAndVoteResponse> {
  try {
    if (isUserLoggedIn()) {
      const response = await EventService.loadRestaurantsAndVotes(
        eventId,
        store.state.userFirebaseUid,
        extended,
        requestMore
      );
      return {
        restaurants: response.data.restaurants ?? [],
        venues: response.data.venues ?? [],
        userInputVotingOptions: response.data.userInputVotingOptions ?? [],
        nextRestaurants: response.data.nextRestaurants ?? [],
      };
    }
  } catch (error) {
    if (error) console.error(error);
  }
}

export function eventIsHappeningNow(
  startTime: string,
  endTime: string,
  specialDays: SpecialDay[]
): boolean {
  const now = dayjs();

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const specialDaysOfTheWeek = specialDays.map((s) => s.day.toUpperCase());
  if (!specialDaysOfTheWeek.includes(weekdays[now.day()])) {
    return false;
  }
  if (parseInt(startTime) === 0 && parseInt(endTime) === 0) {
    return true;
  }
  // Convert the time value to a string
  const startTimeString = String(startTime).padStart(4, "0");
  // Extract hours and minutes from the time string
  const startHours = parseInt(startTimeString.slice(0, 2));
  const startMinutes = parseInt(startTimeString.slice(2, 4));

  // Convert the time value to a string
  const endTimeString = String(endTime).padStart(4, "0");
  // Extract hours and minutes from the time string
  const endHours = parseInt(endTimeString.slice(0, 2));
  const endMinutes = parseInt(endTimeString.slice(2, 4));

  let dayOffset = 0;
  if (parseInt(endTimeString) < parseInt(startTimeString)) {
    // ex. 2000 - 0200 (8pm - 2am) should land here
    dayOffset += 1;
  }

  const specialDayAndHourStartTime = dayjs()
    .hour(startHours)
    .minute(startMinutes);
  const specialDayAndHourEndTime = dayjs()
    .add(dayOffset, "day")
    .hour(endHours)
    .minute(endMinutes);
  return specialDayAndHourStartTime < now && specialDayAndHourEndTime > now;
}

export function googleUrl(v, p = ""): string {
  const vicinity = v ? encodeURIComponent(v) : "";
  const placeId = p ? encodeURIComponent(p) : "";
  return (
    "https://www.google.com/maps/search/?api=1&query=" +
    vicinity +
    (p ? "&query_place_id=" + placeId : "")
  );
}

export function getQualifiedWebsiteName(website: string): string {
  // Initialize formattedWebsite with the original website
  let formattedWebsite = website;

  // Check if the website starts with 'http://' or 'https://', if not, default to 'https://'
  if (
    !formattedWebsite.startsWith("http://") &&
    !formattedWebsite.startsWith("https://")
  ) {
    formattedWebsite = "https://" + formattedWebsite;
  }

  // Ensure 'www.' is present after the protocol
  const protocol = formattedWebsite.startsWith("https://")
    ? "https://"
    : "http://";
  if (!formattedWebsite.includes(protocol + "www.")) {
    // Replace the protocol with protocol + "www."
    formattedWebsite = formattedWebsite.replace(protocol, protocol + "www.");
  }

  return formattedWebsite;
}

export function instagramUrl(i): string {
  return i ? `https://www.instagram.com/${encodeURIComponent(i)}` : "";
}

export function getStrippedTeamName(teamName: string): string {
  return (teamName ?? "")
    .split("")
    .filter((c) => c.charCodeAt(0) <= 127)
    .join("")
    .replaceAll(" ", "");
}

export async function shareLinkToTeam(
  teamId: string,
  teamName: string
): Promise<void> {
  // Remove any non-ascii characters from the team name
  const strippedTeamName = getStrippedTeamName(teamName);
  await Share.share({
    title: "gophrr.io",
    text: "Come join my team on gophrr! Download the app at gophrr.io, then join my team with this link.",
    url: "https://www.gophrr.io/join/" + teamId + "/" + strippedTeamName,
    dialogTitle: "Share",
  });
}

export function sendGtagEvent(gtag: any, name: string, obj: any): void {
  try {
    if (process?.env?.NODE_ENV !== "development") {
      gtag.event(name, obj);
    } else {
      console.log("Gtag event would send in prod: " + name);
    }
  } catch (e) {
    console.error(e);
  }
}

export function linkToTeam(teamName: string, key: string): string {
  const strippedTeamName = getStrippedTeamName(teamName);
  return "https://www.gophrr.io/join/" + key + "/" + strippedTeamName;
}

export function debounce(func, timeout = 300) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export async function signInAsGuest(): Promise<void> {
  try {
    const result = await FirebaseAuthentication.signInAnonymously();
    const token = await FirebaseAuthentication.getIdToken();
    const shouldGoHome = await setFrontEndDetailsWithUidAndToken(
      result.user.uid,
      token.token
    );
    store.commit("setSignedInAsGuest", true);
    if (shouldGoHome) {
      router.push("/Dashboard");
    }
  } catch (error) {
    const toast = useToast();
    toast("Problem signing in as guest. Try again later", {
      timeout: 2500,
      type: TYPE.ERROR,
      hideProgressBar: true,
    });
    console.error(error.code);
    console.error(error.message);
  }
}

// export async function signInWithGoogle(upgrade = false): Promise<void> {
//   try {
//     const platform = Capacitor.getPlatform();
//     const auth = await getAuth();
//     const currentUser = auth.currentUser;
//     const oldUid = store.state.userFirebaseUid ?? null;
//     let linkingCredential = null,
//       result = null;
//     const googleAuthProvider = new GoogleAuthProvider();
//     if (upgrade) {
//       // TODO these platforms should be fixd to split out and rediret if native
//       //   if (platform === "web") {
//       linkingCredential = await linkWithPopup(currentUser, googleAuthProvider);
//       //   } else if (platform === "android" || platform === "ios") {
//       // store.commit("setIsRedirectingForLogin", true);
//       // await linkWithRedirect(currentUser, googleAuthProvider);
//       // return; // Exit the function early as the redirect will occur
//       //   }
//     } else {
//       // TODO these platforms should be fixd to split out and rediret if native
//       //   if (platform === "web") {
//       result = await signInWithPopup(auth, googleAuthProvider);
//       //   } else if (platform === "android" || platform === "ios") {
//       // Use signInWithRedirect for mobile platforms
//       // store.commit("setIsRedirectingForLogin", true);
//       // await signInWithRedirect(auth, googleAuthProvider);
//       // return; // Exit the function early as the redirect will occur
//       //   }
//     }
//     const feDetailsCredential = upgrade ? linkingCredential : result;
//     const shouldGoHome = await setFrontEndDetails(feDetailsCredential);
//     if (oldUid !== null && linkingCredential !== null) {
//       await runPostLinkingUpdates(linkingCredential, oldUid);
//     }

//     if (shouldGoHome) {
//       router.push("/Dashboard");
//     }
//   } catch (e) {
//     handleGoogleSignInError(e);
//   }
// }

// Handle the redirect result in your app's entry point or a specific component
// export async function handleRedirectResult(): Promise<void> {
//   try {
//     const result = await getRedirectResult(await getFirebaseAuth());
//     if (result) {
//       // User signed in or linking completed
//       const oldUid = store.state.userFirebaseUid ?? null;
//       const feDetailsCredential = result;
//       const shouldGoHome = await setFrontEndDetails(feDetailsCredential);
//       // Handle successful sign in or linking
//       if (oldUid !== null && store.state.signedInAsGuest) {
//         await runPostLinkingUpdates(result, oldUid);
//       }

//       if (shouldGoHome) {
//         router.push("/Dashboard");
//       }
//     }
//   } catch (error) {
//     console.error(error.code);
//     console.error(error.message);
//   }
// }

// function handleGoogleSignInError(e: AuthError): void {
//   const toast = useToast();
//   if (e.code === "auth/popup-closed-by-user") {
//     toast("Sign in with Google was cancelled.", {
//       type: TYPE.ERROR,
//     });
//   } else if (e.code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE) {
//     toast(
//       "Merging a guest account with an existing account isn't supported. Sign out and sign into your account",
//       {
//         type: TYPE.ERROR,
//       }
//     );
//   } else if (e.code === "auth/popup-blocked") {
//     toast("Please try again. Sign-in blocked by browser.", {
//       type: TYPE.DEFAULT,
//     });
//   } else {
//     console.log(e.message);
//     toast("Error: " + e.message, {
//       type: TYPE.ERROR,
//     });
//   }
// }

export async function runPostLinkingUpdates(
  userCredential: UserCredential,
  oldUid: string
): Promise<void> {
  try {
    const response = await UserService.mergeUserFirebaseUid(
      oldUid,
      userCredential.user.uid
    );
    if (response.data.problem === false) {
      store.commit("setUserAlias", response.data.userAlias.toLowerCase());
      store.commit("setSignedInAsGuest", false);
      const toast = useToast();
      toast("Guest account successfully linked.", {
        type: TYPE.DEFAULT,
        timeout: 5000,
      });
    } else {
      console.log(response.data.msg);
      const toast = useToast();
      toast("Error linking guest account.", {
        type: TYPE.ERROR,
        timeout: 5000,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

// export async function signInWithFacebook() {
//   try {
//     throw new Error("Not implemented");
//     // const provider = new FacebookAuthProvider();
//     // provider.addScope("profile");
//     // provider.addScope("email");
//     // const result = await signInWithPopup(auth, provider);
//     // const shouldGoHome = await setFrontEndDetails(result);
//     // if (shouldGoHome) {
//     //   router.push("/Dashboard");
//     // }
//   } catch (e) {
//     if (e.code === "auth/popup-closed-by-user") {
//       const toast = useToast();
//       toast("Sign in with Facebook was cancelled", {
//         type: TYPE.ERROR,
//       });
//     } else {
//       throw new Error(e.message);
//     }
//   }
// }

export async function processTeamRedirects(): Promise<void> {
  for (const t of store.state.teamUuidsToAddFromRedirect) {
    try {
      const r = await TeamsService.joinTeamWithRedirect(
        t.teamUuid,
        store.state.userFirebaseUid,
        t.key
      );
      if (r.data.msg) {
        const toast = useToast();
        toast(r.data.msg, {
          type: TYPE.DEFAULT,
          timeout: 5000,
        });
      }
    } catch (e) {
      console.log(e);
      const toast = useToast();
      toast("Failed to join team.", {
        type: TYPE.ERROR,
        timeout: 5000,
      });
    }
  }
  store.commit("clearTeamUuidRedirects");
}

export function getGoogleMapsUrlFromCoordinates(
  lat: number | string,
  long: number | string
): string {
  // https://www.google.com/maps/search/?api=1&query=47.5951518%2C-122.3316393
  return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
}

// returns a string that represents a unique identifier for a vote
export function getVoteIdentifier(type: VoteType, id: number | string): string {
  if (type === "n" || type === "w" || type === "u" || type === "g") {
    return type + "|" + id.toString();
  } else {
    throw new Error("Not implemented");
  }
}

import { DayOfTheWeek } from "@/types";

export const weekdays: Array<DayOfTheWeek> = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
